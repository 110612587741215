// src/components/CompleteButton.js
import React from 'react';
import './CompleteButton.css'; // Importez le fichier CSS
import theme from '../config/theme'; // Importez le thème

const CompleteButton = ({ color = 'success', text = 'Terminé' }) => {
  const colors = theme[color] || theme.success; // Utilisez la couleur fournie ou par défaut 'success'

  return (
    <span className="complete-button" style={{gap:'8px', width: 'fit-content', backgroundColor: colors.background, 'border-radius': '5px','padding': '0px 8px 0px 8px',height:'24px',display: 'flex', 'align-items': 'center', 'align-content': 'center'}}>
      <i class="fa-regular fa-circle-check" style={{ color: colors.text, width:'16px',heigt:'16px' }}></i>
      <span className="text" style={{ 
        color: colors.text,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 600,
        size: '12px',
        'font-size': '12px',
        'line-height': '12px',
        'text-align':'right',
         }}
                  >{text}</span>
    </span>
  );
};

export default CompleteButton;