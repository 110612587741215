import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CustomBox from '../components/CustomBox';
import MangaVolumeFiche from '../components/MangaVolumeFiche';

const MangaCollection = ({ editor, title, volumes }) => {
  return (
    <CustomBox>
        <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: 'Poppins', fontWeight: 600, color: '#0A2E52'}}>
          {editor}
        </Typography>
        <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '40px', lineHeight: '40px', color: '#0A2E52', mt:1}}>
          {title}
        </Typography>
        <Grid container spacing={4} justifyContent="center" sx={{mt:4}}>
          {volumes.map((manga) => (
              <MangaVolumeFiche posseder={manga.posseder} key={manga.id_volume} volume={manga} clickable={true} titre={manga.titre} titre_edition={manga.titre_edition} />
          ))}
        </Grid>
    </CustomBox>
  );
};

export default MangaCollection;