import { Box, styled } from '@mui/material';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.customBox,
    borderRadius: '20px',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
    },
}));

export default CustomBox;