import {Typography, Container, Grid, TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, IconButton } from '@mui/material';
import CustomBox from '../components/CustomBox';
import CustomTypography from '../components/CustomTypography';
import React, { useEffect, useState } from 'react';
import Badge from '../components/Badge';
import TitrePrincipale from '../components/TitrePrincipale';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import { useAuth } from '../context/auth-context';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ProfilUtilisateur = ({ toggleTheme, mode }) => {
	const [badges, setBadges] = useState([]);
	const [messages, setMessages] = useState([]);
	const [nbPoint, setNbPoint] = useState('');
	const [isSwitchAutoriserOn, setIsSwitchAutoriserOn] = useState('');

	const { user } = useAuth();


	
	const handleThemeSwitchChange = async (event) => {
		const url =
			process.env.NODE_ENV === 'development'
				? 'http://gc/info-utilisateur.php'
				: '/api/info-utilisateur.php';
		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ maj_theme: event.target.checked }),
				credentials: 'include'
			});

			console.log("!!!!!!!!!!!!!");
			console.log(event.target.checked)
			//console.log(response.text());

			if (!response.ok) throw new Error('Réponse réseau non réussie');

			const result = await response.json();
				console.log(result);

		} catch (error) {
			console.error('Erreur lors de la récupération des données:', error);
		}
	};

	const handleSwitchChange = async (event) => {
		setIsSwitchAutoriserOn(event.target.checked);

		const url =
			process.env.NODE_ENV === 'development'
				? 'http://gc/info-utilisateur.php'
				: '/api/info-utilisateur.php';
		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ maj_autoriser_vue: event.target.checked }),
				credentials: 'include'
			});

			console.log("!!!!!!!!!!!!!");
			//console.log(response.text());

			if (!response.ok) throw new Error('Réponse réseau non réussie');

			const result = await response.json();
				console.log(result);

		} catch (error) {
			console.error('Erreur lors de la récupération des données:', error);
		}
	};

	const handleDelete = async (id) => {
		// Filtrer les messages pour supprimer celui avec l'ID correspondant
		const updatedMessages = messages.filter((message) => message.id !== id);
		setMessages(updatedMessages);

		const url =
			process.env.NODE_ENV === 'development'
				? 'http://gc/message.php'
				: '/api/message.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ effacer_destinataire: id }), // Passer l'ID du message à supprimer
					credentials: 'include',
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				console.log(result);

			} catch (error) {
				console.error('Erreur lors de la suppression du message:', error);
			}
	};

	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-utilisateur.php'
					: '/api/info-utilisateur.php';
				try {
					const response = await fetch(url, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ ses_badges: true }),
						credentials: 'include'
					});

					if (!response.ok) throw new Error('Réponse réseau non réussie');

					const result = await response.json();


					console.log(result.liste_message); // affiche {bar: 'baz', bar2: 'baz', bar3: 'baz'}

					setBadges(result.liste_badge);
					setMessages(result.liste_message);
					setNbPoint(result.nb_point_contribution);
					if(result.autoriser_vue_collection){
						setIsSwitchAutoriserOn(true);
					}
					else{
						setIsSwitchAutoriserOn(false);
					}
					

					console.log(result.autoriser_vue_collection);
					console.log(result.autoriser_vue_collection);


				} catch (error) {
					console.error('Erreur lors de la récupération des données:', error);
				}
			};

			fetchDonnees();
		}, []);


  return (
    <Container maxWidth="lg" sx={{ mb:4}}>
      <CustomBox sx={{
        mt: 10
      }}>

        <TitrePrincipale>Mon profil</TitrePrincipale>

				<CustomTypography>
					Nom d'utilisateur : {user ? user.pseudo : 'Ah ....'}
				</CustomTypography>
				<CustomTypography>
					Autoriser les visiteurs à voir votre collection de manga?
					<Switch
						checked={isSwitchAutoriserOn}
						onChange={handleSwitchChange}
						{...label}
					/>
				</CustomTypography>

				{isSwitchAutoriserOn && (
					<CustomTypography>
						Liens de partage de la collection : https://www.gestionnaire-collection.com/collection_manga/{user.id}/
					</CustomTypography>
				)}
				<CustomTypography>
					Mode sombre
					<Switch
						onClick={toggleTheme}
						onChange={handleThemeSwitchChange}
						checked={mode === 'dark'}
						{...label} />
					Passer en mode {mode === 'light' ? 'sombre' : 'clair'}
				</CustomTypography>
				<CustomTypography>
					Point contribution : {nbPoint}
        </CustomTypography>
      </CustomBox>



      <CustomBox sx={{
        mt: 4
      }}>
        <TitrePrincipale>Mes badges</TitrePrincipale>

        <Grid container spacing={4} justifyContent="center" alignItems="center">
        {badges.length > 0 ? (
          badges.map((badge, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Badge src={`/image/badge/${badge.id}.webp`} texte={badge.nom} />
            </Grid>
          ))
        ) : (
          <Grid item xs={2}>
            <Badge
              src="/image/badge/defaut.jpg" // Remplacez par l'URL de votre badge par défaut
              texte="Aucun badge"
            />
          </Grid>
        )}
      </Grid>
      </CustomBox>
      <CustomBox sx={{
        mt: 4
      }}>
        <TitrePrincipale>Mes messages</TitrePrincipale>
        <TableContainer component={Paper}>
					<Table aria-label="messages table">
						<TableHead>
							<TableRow>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Expéditeur</Typography></TableCell>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Date d'envoi</Typography></TableCell>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Titre</Typography></TableCell>
								<TableCell align="center"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>



							{messages.map((message) => (
								<TableRow key={message.id}>
									<TableCell>
										<Typography variant="body1" color="error" fontWeight="bold">
	                    MAD5D (Webmestre)
	                  </Typography>
	                </TableCell>
									<TableCell>{message.date_envoie}</TableCell>
									<TableCell>{message.titre}</TableCell>
									<TableCell align="center">
										<IconButton aria-label="delete" onClick={() => handleDelete(message.id)}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}


						</TableBody>
					</Table>
				</TableContainer>
			</CustomBox>
		</Container>
)}

export default ProfilUtilisateur;