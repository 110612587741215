import {Typography, Container, } from '@mui/material';
import CustomBox from '../components/CustomBox';
import CustomTypography from '../components/CustomTypography';
import React from 'react';

const Cgu = () => {
	return (
		<Container maxWidth="lg" sx={{ mb:4}}>




		<CustomBox sx={{
			mt: 10
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_alpha" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Conditions générales d'utilisation
			</Typography>

			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				CGU
			</Typography>


			<CustomTypography>
				Notre plateforme vous est proposée sous certaines conditions d'utilisation. En accédant à notre plateforme et en l'utilisant, vous acceptez de vous conformer à ces conditions. Si vous n'êtes pas d'accord avec ces conditions, nous vous demandons de ne pas utiliser notre plateforme. Nous nous réservons le droit de modifier ces conditions à tout moment, il est donc de votre responsabilité de vérifier régulièrement pour vous assurer d'être informé des éventuels changements.
			</CustomTypography>
		</CustomBox>











		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 1 : Objet
			</Typography>
			<CustomTypography>
				Les présentes conditions générales d'utilisation (CGU) ont pour objet de définir les modalités et les conditions dans lesquelles d'une part, l'utilisateur du site peut accéder et utiliser les services proposés par le site, et d'autre part, la manière dont le site exploite ces services. Les présentes CGU s'appliquent à toutes les étapes de consultation, d'inscription et d'utilisation du site. L'utilisation du site implique l'acceptation pleine et entière des CGU.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 2 : Propriété intellectuelle
			</Typography>
			<CustomTypography>
				1. Le code source du site est une propriété intellectuelle personnelle de l'exploitant du site. Toute reproduction, utilisation ou exploitation partielle ou totale de ce code source sans autorisation expresse est interdite.
			</CustomTypography>
			<CustomTypography>
				2. Les visuels des bandes dessinées, mangas, livres, et autres contenus présents sur le site sont la propriété de leurs éditeurs respectifs. L'utilisation de ces contenus sans autorisation de l'éditeur est interdite. L'utilisateur s'engage à ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter à quelque fin commerciale que ce soit tout ou partie des contenus du site.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 3 : Cookies
			</Typography>
			<CustomTypography>
				Notre site utilise des cookies pour assurer le bon fonctionnement de certaines fonctionnalités. L'acceptation de ces cookies n'est pas obligatoire, mais ils sont nécessaires pour un fonctionnement optimal du site. Nous n'utilisons qu'un cookie de fonctionnement et nous n'effectuons aucune collecte d'informations personnelles via les cookies. Les cookies utilisés par notre site sont automatiquement effacés à la fermeture du navigateur de l'utilisateur.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 4 : Responsabilité du site
			</Typography>
			<CustomTypography>
				Nous mettons tout en œuvre pour fournir un service de qualité à nos utilisateurs. Cependant, nous ne pouvons pas garantir l'absence de disfonctionnements ou d'erreurs dans l'accès au site ou dans son utilisation. En aucun cas, nous ne pourrons être tenus responsables des dommages directs ou indirects, tels que, mais non limitatifs, la perte de données, la perte de bénéfice, ou tout autre perte pécuniaire ou commerciale découlant de l'utilisation ou de l'impossibilité d'utiliser le site. L'utilisateur est seul responsable de ses actions sur le site et de la mise en place de toutes les mesures appropriées de sauvegarde de ses données et de ses propres équipements informatiques en vue de protéger ces données contre toute atteinte. Il est important de noter que notre site est en constante évolution, et que nous nous réservons le droit de modifier ou de retirer des fonctionnalités à tout moment sans préavis. Nous ne pourrons être tenus responsables des dommages causés par ces modifications ou suppressions de fonctionnalités.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 5 : Prix
			</Typography>
			<CustomTypography>
				L'utilisation de notre site est entièrement gratuite. Aucun paiement ni abonnement n'est requis pour accéder aux fonctionnalités et services proposés sur le site. Nous nous réservons le droit de mettre en place des fonctionnalités ou services payants à l'avenir, mais cela sera clairement indiqué et ne concernera pas l'utilisation de base du site.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 6 : Durée d'acceptation
			</Typography>
			<CustomTypography>
				L'utilisateur accepte les conditions générales d'utilisation en accédant au site et en utilisant les services proposés. Si l'utilisateur ne les accepte pas, il doit immédiatement cesser d'utiliser le site. Nous nous réservons le droit de modifier les conditions générales d'utilisation à tout moment. Si l'utilisateur continue d'utiliser le site après la modification des conditions générales d'utilisation, cela signifie qu'il accepte les modifications apportées. Si à un moment donné, l'utilisateur ne souhaite plus accepter les conditions générales d'utilisation, il doit immédiatement cesser d'utiliser le site.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 7 : Inscription
			</Typography>
			<CustomTypography>
				L'inscription sur notre site permet d'accéder à certaines fonctionnalités supplémentaires. Cependant, l'inscription n'est pas obligatoire pour utiliser les fonctionnalités de base du site. L'inscription d'un mineur doit se faire avec l'accord de l'autorité parentale. En s'inscrivant, l'utilisateur certifie avoir au moins 13 ans et avoir obtenu l'accord de l'autorité parentale si il est mineur. Certaines informations sont obligatoires lors de l'inscription afin de limiter l'accès au site à un public approprié et d'assurer la sécurité des utilisateurs. Ces informations seront traitées conformément à notre politique de confidentialité. L'utilisateur est responsable de la véracité des informations fournies lors de son inscription et s'engage à les mettre à jour en cas de changement. Nous nous réservons le droit de refuser ou de résilier l'inscription d'un utilisateur si les informations fournies sont inexactes ou si l'utilisateur enfreint les conditions générales d'utilisation.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 8 : Confidentialité
			</Typography>
			<CustomTypography>
				Confidentialité Les informations transmises par l'utilisateur lors de son inscription ou de l'utilisation du site sont traitées de manière confidentielle. Nous nous engageons à ne pas les divulguer à des tiers, sauf dans les cas où la loi nous y oblige ou si cela est nécessaire pour assurer la sécurité du site. Ces informations peuvent être utilisées à des fins de statistiques, mais toujours de manière anonyme. L'utilisateur s'engage à conserver confidentiel son mot de passe et à ne pas le partager avec des tiers. Toute utilisation du compte de l'utilisateur sera considérée comme ayant été effectuée par lui-même, et il sera donc responsable de toutes les conséquences de cette utilisation. Nous nous réservons le droit de suspendre ou de résilier le compte de l'utilisateur en cas de violation de cette disposition.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 9 : Litiges
			</Typography>
			<CustomTypography>
				Tout litige découlant de l'utilisation du site ou de l'application des présentes conditions générales d'utilisation sera tout d'abord soumis à une tentative de résolution à l'amiable. Si une telle tentative de résolution à l'amiable échoue, le litige sera soumis aux tribunaux compétents. Les présentes conditions générales d'utilisation et l'utilisation du site sont régies et interprétées conformément aux lois de la république française.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 10 : Contact
			</Typography>
			<CustomTypography>
				Pour toute question ou problème lié à l'utilisation du site, l'utilisateur peut contacter notre webmaster à l'adresse admin@drouin.ovh. Nous ferons tout notre possible pour répondre rapidement et efficacement à toutes les demandes. Il est important de fournir une description claire et détaillée de la question ou du problème rencontré pour faciliter la résolution de celui-ci.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 11 : Gestion des données personnelles
			</Typography>
			<CustomTypography>
					En utilisant notre site, vous acceptez que nous collectons et utilisons vos informations personnelles conformément à notre politique de confidentialité. Vous avez le droit de consulter, de modifier ou de supprimer vos données personnelles à tout moment en nous contactant à l'adresse admin@drouin.ovh.
			</CustomTypography>
			<CustomTypography>
						Nous prenons des mesures de sécurité pour protéger vos informations personnelles et vous garantissons que vos données ne seront pas partagées avec des tiers sans votre consentement.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Article 12 : Utilisation de Mangator
			</Typography>
			<CustomTypography>
				<b>Données collectées lors des parties de Mangator : </b>Lorsque vous utilisez notre jeu Mangator, des données relatives à votre partie, y compris vos choix et réponses, sont collectées et sauvegardées. Ces données sont utilisées pour améliorer l'expérience utilisateur du jeu et pour son fonctionnement adaptatif.
			</CustomTypography>
			<CustomTypography>
				<b>Amélioration du jeu : </b>Mangator est en version BETA. En jouant, vous contribuez à son amélioration. Les données collectées lors de vos parties nous permettent d'ajuster et d'améliorer les algorithmes du jeu pour offrir une meilleure expérience à l'ensemble des utilisateurs.
			</CustomTypography>
			<CustomTypography>
				<b>Protection des données de Mangator : </b>Les données collectées lors de vos parties sont traitées avec la même confidentialité et sécurité que l'ensemble de vos données personnelles. Elles ne sont en aucun cas partagées avec des tiers ni utilisées à des fins commerciales.
			</CustomTypography>
			<CustomTypography>
				<b>Droit d'accès et de suppression : </b>Conformément à notre politique de confidentialité, vous avez le droit de consulter, de modifier ou de supprimer les données collectées lors de vos parties. Pour cela, veuillez nous contacter à l'adresse admin@drouin.ovh.
			</CustomTypography>
		</CustomBox>
</Container>
)}

export default Cgu;