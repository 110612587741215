import React, { useEffect, useState,useRef  } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box, Typography, Button  } from '@mui/material';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const RepartitionParType = ({ repartitionData }) => {
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});

	const chartRef = useRef();
	const [tooltipLabels, setTooltipLabels] = useState([]);
	const [displayCount, setDisplayCount] = useState(10);

	useEffect(() => {
		const sortedData = [...repartitionData].sort((a, b) => b.nb_genre - a.nb_genre);

		const labels = [];
		const data = [];
		const tooltips = [];

		const backgroundColor = [
			'rgb(32, 119, 180)',
			'rgb(255, 127, 14)',
			'rgb(44, 160, 44)',
			'rgb(214, 39, 40)',
			'rgb(148, 103, 189)',
			'rgb(140, 86, 75)',
			'rgb(227, 119, 194)',
			'rgb(126, 126, 126)',
		];


		let otherTotal = 0;

		// Limiter aux premiers éléments (5 ou 10) et regrouper les autres
		sortedData.forEach((type, index) => {
			if (index < displayCount) {
				labels.push(`${type.genre} ${type.nb_genre}`);
				data.push(type.nb_genre);
				tooltips.push(type.genre); // Stocker uniquement le genre pour le tooltip
			} else {
				otherTotal += type.nb_genre;
			}
		});

		if (otherTotal > 0) {
			labels.push(`Autres ${otherTotal}`);
			data.push(otherTotal);
			tooltips.push('Autres');
		}

		setChartData({
			labels: labels,
			datasets: [
				{
					label: 'Répartition par type',
					data: data,
					backgroundColor: backgroundColor,
					borderColor: 'rgb(255, 255, 255)',
					borderWidth: 1,
				},
			],
		});

		setTooltipLabels(tooltips);
	}, [repartitionData,displayCount]);

	const handleClick = (event, elements) => {
		console.log("clic");
		console.log(elements);

		if (elements && elements.length > 0) {
			console.log('ok');
			const chart = chartRef.current;

			console.log(chart);
			if (chart) {
				console.log("Je suis dans le if chart");
				const element = elements[0];
				console.log(element);
				const datasetIndex = element.datasetIndex;
				console.log(datasetIndex);
				const index = element.index;
				console.log(index);
				const label = chartData.labels[index];
				console.log('Label:', label);
				const value = chartData.datasets[datasetIndex].data[index];
				console.log('Value:', value);
			}
        }


	};

	const toggleDisplayCount = () => {
		setDisplayCount(displayCount === 10 ? 10000 : 10);
	};

	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top', // 'top', 'bottom', 'left', 'right'
			},
			tooltip: {
				callbacks: {
					label: (tooltipItem) => {
						return tooltipLabels[tooltipItem.dataIndex]; // Utiliser le tableau des tooltips
					},
				},
			},
		},
		onClick: handleClick,
	};

	return (
		<Box>
			<Typography variant="h5" align="center" gutterBottom>
				Répartition par type (par nombre de collections)
			</Typography>
			<Doughnut data={chartData} ref={chartRef} options={chartOptions} onClick={(event, elements) => handleClick(event, elements)} />
			<Box textAlign="center" mt={2}>
				<Button variant="contained" onClick={toggleDisplayCount}>
					Afficher {displayCount === 10 ? 'Toutes les' : '10'} valeurs
				</Button>
			</Box>
		</Box>

	);
};

export default RepartitionParType;