import React from 'react';
import { Box, Typography } from '@mui/material';

const TuileArticle = ({ href, src, caption }) => {
  return (
	<Box sx={{
		position:'relative'
	}}>
		<a href={href}>
			<img
				src={src} // Remplacez par le chemin réel de l'image
				alt="Akata Editions"
				style={{ width: '100%', height: 'auto' }}
			/>
			<Typography
				variant="body"
				component="p"
				gutterBottom
				sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 700,
					fontSize: '20px',
					lineHeight: '24px',
					mt: 2,
					mb: 2,
					position: 'absolute',
					bottom: '10px',
					color: 'white',
					left: '10px',
					right: '10px',
					backgroundColor: 'rgba(0, 0, 0, 0.6)',
				}}>
				{caption}
			</Typography>
		</a>
	</Box>
	);
};

export default TuileArticle;