// src/components/Navbar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, Menu, MenuItem,Link as MuiLink  } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, useTheme } from '@mui/material/styles';
import { useAuth } from '../context/auth-context';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import '../fonts/fonts.css';
import MangaNavBar from './MangaNavBar';

const Navbar = () => {
  const theme = useTheme();

  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  const Logo = styled(LogoSVG)(({ theme }) => ({
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      height: '30px',
    },
    width: 'auto',
  }));


  const textStyle = {
    fontFamily: 'Poppins, sans-serif', // Utiliser la police Poppins
    position: 'relative',
    fontWeight: 700, // Utiliser le style bold
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };



  const shouldDisplayMangaNav = isAuthenticated && [
    '/accueil-manga/',
    '/manga-personnel/',
    '/manga-statistique-perso/',
    '/prochaine-sortie-perso/',
    '/manga-personnel-couverture/',
    '/manga-pal-perso/',
    '/manga-manquant-perso/',
    '/manga-envie-perso/',
    '/recap-manga-perso-achat/',
    '/recap-manga-perso-lecture/',
    '/fiche-manga/',
  ].some((route) => location.pathname.startsWith(route));




  return (
    <div>
      <AppBar position="static" sx={{
        height: '204px',
        backgroundColor: theme.palette.nav.background,
      }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters style={{ height: '100%', display
          : 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box display="flex" alignItems="center" style={{ position: 'relative', top: '40px' }}>
              <Logo />
            </Box>
            <Box display="flex" alignItems="center" style={{ position: 'relative', top: '40px' }}>
              <Typography variant="h6" color="inherit" style={textStyle}>
              


{isAuthenticated && user ? (
 
<MuiLink
  component={Link} 
  to="/profil-utilisateur/"
        sx={{
          textDecoration: 'none',
          color: 'white',
        }}
      >
        <span>
          {user.pseudo}
        </span>
      </MuiLink>
) : (
<Button
  variant="contained"
  component={Link} to="/connexion/" 
  sx={{
    backgroundColor: 'white',
    color: '#0A2E52',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: { xs: '10px', sm: '20px' },
    textTransform: 'none',
    padding: '10px 20px',
    borderRadius: '20px',
    lineHeight: { xs: '20px', sm: '32px' },
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'flex',
    alignItems: 'center',
    '& .fa-circle-user': {
      fontSize: { xs: '20px', sm: '35px' },
      width: { xs: '20px', sm: '35px' },
      height: { xs: '20px', sm: '35px' },
      marginRight: '8px',
      color: '#1A73E8',
    },
  }}
>
  <i className="fa-solid fa-circle-user"></i>
  Se connecter
</Button>
)}            

    
              </Typography>
            </Box>
          </Toolbar>
        </Container>

        <Container maxWidth="lg" style={{ position: 'relative', top: '76px' }}>
            <Box display="flex" alignItems="center" style={{ position: 'relative' }}>
            <Button style={{ height:'64px', padding:'0px 32px'}} color="inherit" component={Link} to="/">Accueil</Button>

            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ height:'64px', padding:'0px 32px'}} 
              color="inherit" 
              endIcon={<KeyboardArrowDownIcon />}
            >
              Lecture
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/index_livre/" onClick={handleClose}>LIVRE</MenuItem>
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/index_bd/" onClick={handleClose}>BD</MenuItem>
              <MenuItem component={Link} to="/accueil-manga/" onClick={handleClose}>MANGA</MenuItem>
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/accueil_comics/" onClick={handleClose}>COMICS</MenuItem>
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/index_periodique/" onClick={handleClose}>PERIODIQUE</MenuItem>
            </Menu>

            <Button
              id="basic-button2"
              aria-controls={open2 ? 'basic-menu2' : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? 'true' : undefined}
              onClick={handleClick2}
              style={{ height:'64px', padding:'0px 32px'}} 
              color="inherit" 
              endIcon={<KeyboardArrowDownIcon />}
            >
              Carte
            </Button>
            <Menu
              id="basic-menu2"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                'aria-labelledby': 'basic-button2',
              }}
            >
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/accueil_carte_telephonique/" onClick={handleClose}>CARTE TELEPHONIQUE</MenuItem>
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/index_carte/" onClick={handleClose}>CARTE A JOUER</MenuItem>
            </Menu>

            <Button
              id="basic-button3"
              aria-controls={open3 ? 'basic-menu3' : undefined}
              aria-haspopup="true"
              aria-expanded={open3 ? 'true' : undefined}
              onClick={handleClick3}
              style={{ height:'64px', padding:'0px 32px'}} 
              color="inherit" 
              endIcon={<KeyboardArrowDownIcon />}
            >
              Figurine
            </Button>
            <Menu
              id="basic-menu3"
              anchorEl={anchorEl3}
              open={open3}
              onClose={handleClose3}
              MenuListProps={{
                'aria-labelledby': 'basic-button3',
              }}
            >
              <MenuItem component="a" href="https://www.gestionnaire-collection.com/accueil_pop/" onClick={handleClose}>POP</MenuItem>
            </Menu>


                
            </Box>
        </Container>
      </AppBar>
      <MangaNavBar isVisible={shouldDisplayMangaNav} />
    </div>
  );
};

export default Navbar;