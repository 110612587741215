import { Container,Grid} from '@mui/material';
import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import SousTitre from '../components/SousTitre';
import TestMangaVolume from '../components/TestMangaVolume';

import React, { useEffect, useState } from 'react';

const MangaPersonnelCouverture = () => {
	const [listes_manga_perso, setListes_manga_perso] = useState([]);

	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ page: 'manga-personnel-couverture' }),
					credentials: 'include'
				});

				//console.log(event.target.checked)
				//console.log(response.text());

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				console.log(result);
				setListes_manga_perso(result);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};

		fetchDonnees();
	}, []);

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			{Object.keys(listes_manga_perso).map((titre,index) => {
				return (
					<CustomBox key={titre} sx={{ mt: index === 0 ? 10 : 4 }}>
						{index === 0 && (
							<TitrePrincipale>Mes mangas</TitrePrincipale>
						)}
						<SousTitre>{titre}</SousTitre>
						<Grid container spacing={4} justifyContent="center" sx={{mt:0, mb:4}}>
							{listes_manga_perso[titre].map((volume, idx) => (
								<TestMangaVolume key={volume.id_volume} volume={volume} />
							))}
						</Grid>
					</CustomBox>
				)
			})}
		</Container>
	)
}

export default MangaPersonnelCouverture;