import {Container , CircularProgress, Typography, Grid, Box} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import SousTitre from '../components/SousTitre';

const ProchaineSortie = ({ mode }) => {

	const [listes_nouveautes, setListes_nouveautes] = useState({});
	const [dates, setDates] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/prochaine_sortie.php'
					: '/api/prochaine_sortie.php';
				try {
					const response = await fetch(url, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({}),
						credentials: 'include'
					});

					if (!response.ok) throw new Error('Réponse réseau non réussie');

					const result = await response.json();

					setListes_nouveautes(result);
					setDates(Object.keys(result));

					
					setLoading(false); 

				} catch (error) {
					console.error('Erreur lors de la récupération des données:', error);
					setLoading(false); 
				}
			};

			fetchDonnees();
		}, []);



	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
		{loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Chargement des données...</Typography>
        </div>
      ) : (
			    <div>
      {dates.map((date, index) => (
        <CustomBox
        	key={date}
			sx={{
				mt: index === 0 ? 10 : 4,
			}}
        >
          {index === 0 && <TitrePrincipale>Nouveautés mangas</TitrePrincipale>} {/* Affiche "Nouveautés mangas" dans le premier box */}
          <SousTitre>{date}</SousTitre>






<Grid container spacing={4} justifyContent="center" sx={{mt:0, mb:4}}>
			        {listes_nouveautes[date].length > 0 ? (
			            listes_nouveautes[date].map((manga) => (
			              <Grid item xs={6} sm={4} md={2.4} key={manga.id}>
			                <Box sx={{ textAlign: 'center' }}>
			                  <a href={`https://www.gestionnaire-collection.com/fiche_manga/e/${manga.id_edition}`} style={{ textDecoration: 'none' }}>
				                	<Box
				                	  component="img"
				                	  src={process.env.PUBLIC_URL + manga.liens_couverture_mini}
				                	  alt={`Manga Cover ${manga.id}`}
				                	  sx={{
				                      width: '100%',
				                      transition: 'transform 0.3s ease-in-out',
				                      '&:hover': {
				                        transform: 'scale(1.1)',
				                      },
				                    }}
				                  />
				                </a>
				                {manga.titre}
			                </Box>
			              </Grid>
			            ))
			          ) : (
			            <Typography variant="h6">Aucun manga disponible</Typography>
			          )}
			      </Grid>



















        </CustomBox>
      ))}
    </div>
    )}
		</Container>


	)
};

export default ProchaineSortie;