import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Chip, styled,TextField, MenuItem, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper  } from '@mui/material';
import axios from 'axios';
import '../fonts/fonts.css';
import PodcastCard from '../components/PodcastCard';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { useAuth } from '../context/auth-context';
import { format } from 'date-fns';

import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import MangaVolume from '../components/MangaVolume';

const CustomChip = styled(Chip)(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: '16px 24px',
  height: '48px',
  borderRadius: '32px',
  '.MuiChip-icon': {
    marginLeft: theme.spacing(2),
  },
  '.MuiChip-label': {
    paddingRight: 0, // Enlever le padding à droite du label
    fontFamily: 'Poppins, sans-serif', // Définir la police Poppins
    fontWeight: 600, // Poids de la police
    fontSize: '16px', // Taille de la police
    lineHeight: '16px', // Hauteur de ligne
  }
}));




const Accueil_manga = () => {
	const { isAuthenticated, user } = useAuth();
	const [titreManga, setTitreManga] = useState('');
	const [resultats, setResultats] = useState([]);

	const [tags, setTags] = useState('');
	const [editeurs, setEditeurs] = useState('');
	const [manga_hasard, setManga_hasard] = useState([]);
	const [manga_dernier, setManga_dernier] = useState('');
	const [dernier_podcast, setDernier_podcast] = useState('');
	const [extrait_manga, setExtrait_manga] = useState('');

	const [editor, setEditor] = useState('');
	const [genre, setGenre] = useState('');
	const [tag, setTag] = useState('');

	const handleEditorChange = (event) => setEditor(event.target.value);
	const handleGenreChange = (event) => setGenre(event.target.value);
	const handleTagChange = (event) => setTag(event.target.value);
	const handleTitreChange = (event) => setTitreManga(event.target.value);


	const handleSearch = async () => {
		const url =
		        process.env.NODE_ENV === 'development'
		    		? 'http://gc/recherche.php'
						: '/api/recherche.php';
				    try {
				    	const response = await axios.get(url, {
							params: { titre_recherche_manga: titreManga }
						});


	    	if (Array.isArray(response.data)) {
        setResultats(response.data);
      } else {
        setResultats([]);
        console.log("ce n'est pas un tableau");
      }







	      console.log(response.data);
	    } catch (error) {
	      console.error('Erreur lors de la récupération des données:', error);
	    }
	  };

const handleRefreshClick = async () => {
	console.log("rafraichir");
			const url_cible =
		        process.env.NODE_ENV === 'development'
		    		? 'http://gc/recherche.php'
						: '/api/recherche.php';
    try {
      const response = await axios.get(url_cible, {
        params: {
          manga_au_hazard: 5,
        },
      });
      console.log('Data received:', response.data);
      // Traitez les données reçues ici
      setManga_hasard(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Gérez les erreurs ici
    }
  };



  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };

	useEffect(() => {
	    const fetchData = async () => {
	    	const url =
		        process.env.NODE_ENV === 'development'
		    		? 'http://gc/info-page.php'
					: '/api/info-page.php';
	    	try {
	    		const response = await axios.get(url, {
						params: { page: 'accueil-manga', nb_manga: '5' }
					});


					setTags(response.data.tags);
					setEditeurs(response.data.editeurs);
					setManga_hasard(Array.isArray(response.data.manga_au_hazard) ? response.data.manga_au_hazard : []);
					setManga_dernier(response.data.dernier_manga);
					setDernier_podcast(response.data.dernier_podcast);
					setExtrait_manga(response.data.extrait_manga);

					console.log("!!!!!!!!!!");
					console.log(response.data.manga_au_hazard);
					console.log(response.data.extrait_manga);
					console.log("Il est la le undefined ?");
	    	} catch (error) {
        		console.error('Erreur lors de la récupération des données:', error);
    		}
    	};

        fetchData();

	}, []);

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
		{isAuthenticated && user.rank === 10 && (
			<CustomChip sx={{mb:-10, backgroundColor: '#6c757d'}} component="a" href="https://www.gestionnaire-collection.com/espace_admin_manga/" icon={<RoomPreferencesIcon />} color="primary" label="Espace admin manga" clickable="true" />
		)}



			<Box component="form" onSubmit={handleSearch} sx={{
	            backgroundColor: 'white',
	            padding: '16px',
	            borderRadius: '20px',
	            py: 4,
	            mt: 10,
	            px: 10
	          }}>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 700,
		              fontSize: '40px',
		              lineHeight: '40px',
		              mt: 2,
		              mb: 2,
		              color: '#0A2E52',
		            }}>
		          Rechercher
		        </Typography>


<Grid container spacing={2} alignItems="center" mb={3}>
	<Grid item xs={12} sm={12} md={12} lg={6}>
		<TextField
			fullWidth
			label="Titre du manga"
			variant="standard"
			value={titreManga}
			onChange={handleTitreChange}
			onKeyDown={handleKeyDown}
		/>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <FormControl fullWidth variant="standard" disabled>
            <InputLabel>Editeur</InputLabel>
            <Select value={editor} onChange={handleEditorChange} label="Editeur">
              <MenuItem value=""><em>Aucun</em></MenuItem>
            	{editeurs && editeurs.length > 0 ? (
					editeurs.map((editeur) => (
						<MenuItem value={editeur.editeur}>{editeur.editeur}</MenuItem>
					))
				) : (
					<Typography>Loading...</Typography>
				)}


            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <FormControl fullWidth variant="standard" disabled>
            <InputLabel>Type de manga</InputLabel>
            <Select value={genre} onChange={handleGenreChange} label="Type de manga">
              <MenuItem value=""><em>Aucun</em></MenuItem>
              <MenuItem value="Shonen">Shonen</MenuItem>
              <MenuItem value="Shojo">Shojo</MenuItem>
              <MenuItem value="Josei">Josei</MenuItem>
              <MenuItem value="Yuri">Yuri</MenuItem>
              <MenuItem value="Yaoi">Yaoi</MenuItem>
              <MenuItem value="Hentai">Hentai</MenuItem>
              <MenuItem value="Fantastique">Fantastique</MenuItem>
              <MenuItem value="Chungnyun">Chungnyun</MenuItem>
              <MenuItem value="Magazine">Magazine</MenuItem>
              <MenuItem value="Manuel">Manuel</MenuItem>
              <MenuItem value="Roman">Roman</MenuItem>
              <MenuItem value="Apprentissage">Apprentissage</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <FormControl fullWidth variant="standard" disabled>
            <InputLabel>Tag manga</InputLabel>
            <Select value={tag} onChange={handleTagChange} label="Tag manga">
              <MenuItem value=""><em>Aucun</em></MenuItem>
              	{tags && tags.length > 0 ? (
					tags.map((tag) => (
						<MenuItem key={tag.id} value={tag.nom}>{tag.nom}</MenuItem>
					))
				) : (
					<Typography>Loading...</Typography>
				)}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<CustomChip
				icon={<SearchIcon />}
				color="primary"
				label="Rechercher"
				clickable="true" 
				onClick={handleSearch}
			/>






{resultats.length > 0 && (
	<Box mt={4}>
        <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Titre</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Genre</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Editeur</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultats.map((manga) => (
                  <TableRow key={manga.id}>
                    <TableCell>
                  		<Link to={`/fiche-manga/m/${manga.id}/`}>{manga.titre}</Link>
                    </TableCell>
                    <TableCell>{manga.genre}</TableCell>
					<TableCell>{manga.editeur}</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	</Box>
)}





		    </Box>

			<CustomBox sx={{
        mt: 4
      }}>
<TitrePrincipale>
		          Des manga au hasard
		        </TitrePrincipale>
		        <Grid container spacing={4} justifyContent="center" sx={{mt:0, mb:4}}>
			        {manga_hasard.length > 0 ? (
			            manga_hasard.map((manga) => (
			              <MangaVolume clickable={true} key={manga.id_volume} volume={manga} />
			            ))
			          ) : (
			            <Typography variant="h6">Aucun volume disponible</Typography>
			          )}
			      </Grid>
			    <CustomChip icon={<RefreshIcon />} color="primary" label="Rafraîchir " onClick={handleRefreshClick} clickable="true" />
		    </CustomBox>

			<CustomBox sx={{
        mt: 4
      }}>
      <TitrePrincipale>
		          Les dernières sorties
		        </TitrePrincipale>
		          
		        <Grid container spacing={4} justifyContent="center" sx={{mt:0, mb:4}}>
			        {manga_dernier.length > 0 ? (
			            manga_dernier.map((manga) => (
										<MangaVolume clickable={true} key={manga.id_volume} volume={manga} />
			            ))
			          ) : (
			            <Typography variant="h6">Aucun manga disponible</Typography>
			          )}
			      </Grid>
			      <CustomChip component={Link} to="/prochaine-sortie/" icon={<CalendarMonthIcon />} color="primary" label="Prochaine sortie" clickable="true" />
		    </CustomBox>
<Grid container spacing={4}>

<Grid item xs={12} md={6}>
		    <Box sx={{
	            backgroundColor: 'white',
	            borderRadius: '20px',
	            mt: 4,
	            px: 10,
	            py: 10
	          }}>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 700,
		              fontSize: '40px',
		              lineHeight: '40px',
		              color: '#0A2E52',
		            }}>
		          Podcast audio
		        </Typography>
		        <Grid spacing={4} justifyContent="center" sx={{mt:0, mb:1}}>
		        	{dernier_podcast.length > 0 ? (
		        		dernier_podcast.map((dernier_podcast) => (
		        		<PodcastCard
			            date={format(dernier_podcast.date_podcast, 'dd/MM/yyyy')}
			            title= {dernier_podcast.titre}
			            transcriptionLink= {`https://www.gestionnaire-collection.com/podcast/${dernier_podcast.id}/`}
			            audioSrc= {`/Audio/Podcast_actu_manga/${dernier_podcast.fichier_podcast}`} // Lien vers le fichier audio
								/>

			          ))
		        	) : (
		            <Typography variant="h6">Aucun podcast disponible</Typography>
		          )}
			      </Grid>
		        <CustomChip component="a" href="https://www.gestionnaire-collection.com/podcast/" icon={<VolumeUpIcon />} color="primary" label="Tous les podcasts" clickable="true" />
		    </Box>
</Grid>



<Grid item xs={12} md={6}>
		    <Box sx={{
	            backgroundColor: 'white',
	            padding: '40px',
	            borderRadius: '20px',
	            mt: 4,
	            px: 10,
	            py: 10
	          }}>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 700,
		              fontSize: '40px',
		              lineHeight: '40px',
		              color: '#0A2E52',
		            }}>
		          Extrait disponible
		        </Typography>
		        <Typography variant="h5" component="h2" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 600,
		              fontSize: '16px',
		              lineHeight: '24px',
		              color: '#0A2E52',
		            }}>
		            {extrait_manga.titre}
		        </Typography>
		        <Grid container justifyContent="center" sx={{mt:3, mb:0}}>
		        <a style={{ width: '100%'}} href={`https://www.gestionnaire-collection.com/extrait/${extrait_manga.id}/`}>
									        <img 
						  src={extrait_manga?.editions?.[0]?.mangas?.[0]
						    ? `${process.env.PUBLIC_URL}/image/image_manga/webp/${extrait_manga.editions[0].id}-${extrait_manga.editions[0].mangas[0].numero}-m.webp`
						    : `${process.env.PUBLIC_URL}/image/default-image.webp`
						  } 
						  alt="Manga Cover" 
						  style={{ width: '100%', height: 'auto' }} 
						/></a>
			        
			      </Grid>
		    </Box>
</Grid>
		    </Grid>



<Box sx={{
	            backgroundColor: 'white',
	            padding: '40px',
	            borderRadius: '20px',
	            mt: 4,
	            px: 10,
	            py: 10
	          }}>
		        <Typography variant="h5" component="h2" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 600,
		              fontSize: '12px',
		              lineHeight: '12px',
		              color: '#0A2E52',
		            }}>
		          	BETA
		        </Typography>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 700,
		              fontSize: '40px',
		              lineHeight: '40px',
		              color: '#0A2E52',
		            }}>
		          	Mangator
		        </Typography>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 400,
		              fontSize: '16px',
		              lineHeight: '24px',
		              color: '#0A2E52',

	            mt: 4,
	            mb: 4,
		            }}>
		          	C'est avec un grand enthousiasme que nous lançons "Mangator", notre tout nouveau jeu conçu pour tester votre passion des mangas. L'idée est simple : pensez à un manga et défiez notre système pour voir s'il parvient à le deviner grâce à une série de questions.
		          	Mais ce n'est pas tout ! "Mangator" est actuellement en phase BETA, ce qui signifie qu'il s'améliore en permanence. Chaque interaction, chaque réponse que vous donnez aide le jeu à évoluer et à devenir plus précis dans ses déductions.
		        </Typography>

{isAuthenticated && user && (
	<a href="https://www.gestionnaire-collection.com/essaie_aki.php">
		        <CustomChip icon={<ArrowOutwardIcon />} color="primary" label="Tester Mangator" clickable="true" />
		        </a>
)}
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 400,
		              fontSize: '16px',
		              lineHeight: '24px',
		              color: '#0A2E52',

	            mt: 4,
	            mb: 4,
		            }}>

		          	Ce jeu exclusif est maintenant accessible depuis la page d'accueil des mangas, mais il y a une petite condition : seuls nos utilisateurs connectés peuvent en profiter. Si vous n'avez pas encore essayé "Mangator", nous vous encourageons vivement à le faire. Non seulement vous passerez un bon moment, mais vous contribuerez aussi à l'amélioration de cette nouvelle fonctionnalité passionnante.
		        </Typography>
		    </Box>



<Box sx={{
	            backgroundColor: 'white',
	            padding: '40px',
	            borderRadius: '20px',
	            mt: 4,
	            px: 10,
	            py: 10
	          }}>
		        <Typography variant="h5" component="h2" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 600,
		              fontSize: '12px',
		              lineHeight: '12px',
		              color: '#0A2E52',
		            }}>
		          	Tuto
		        </Typography>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 700,
		              fontSize: '40px',
		              lineHeight: '40px',
		              color: '#0A2E52',
		            }}>
		          	Comment ranger ses manga ?
		        </Typography>
		        <Typography variant="h5" component="h1" gutterBottom sx={{
		              fontFamily: 'Poppins, sans-serif',
		              fontWeight: 400,
		              fontSize: '16px',
		              lineHeight: '24px',
		              color: '#0A2E52',

	            mt: 4,
	            mb: 4,
		            }}>
		          	Je t’ai préparé un guide complet sur comment ranger tes mangas, et plus largement toute ta collection. Tu y trouveras des exemples de mobilier, des systèmes de tri et pleins d’autres conseils.
		        </Typography>
		        <CustomChip component={Link} to="/comment-ranger-ses-manga/" color="primary" label="Découvrir le guide" clickable="true" />
		    </Box>


		</Container>
	);
};

export default Accueil_manga;