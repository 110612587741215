import React from 'react';
import VersionItem from './VersionItem';
import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';

const VersionList = ({ versions }) => {
	return (
		<div>
			{versions.map((version, index) => (
				<CustomBox key={version.id} sx={{
					mt:4,
					mb:4
				}}>
					{index === 0 && (
						<TitrePrincipale>Historique de version</TitrePrincipale>
					)}					
					<VersionItem version={version} isFirst={index === 0} />
				</CustomBox>
			))}
		</div>
	);
};

export default React.memo(VersionList);
