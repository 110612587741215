import React from 'react';
import { Grid } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Composant Volume, séparé et mémorisé
const TestMangaVolume = React.memo(({ volume }) => {
  return (
    <Grid item xs={6} sm={4} md={2.4} key={volume.numero}>
      <LazyLoadImage
        alt={`${volume.titre} cover`}
        src={`https://cdn.gestionnaire-collection.com${process.env.PUBLIC_URL}${volume.liens_image}`}
        effect="blur"
        style={{
          width: '100%',
        }}
      />
      Tome {volume.numero}
    </Grid>
  );
});

export default TestMangaVolume;