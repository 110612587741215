// src/sections/FicheManga.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import '../fonts/fonts.css';
import CompleteButton from '../components/CompleteButton';
import MangaCollection from '../components/MangaCollection';
import TitrePrincipale from '../components/TitrePrincipale';
import TitreSection from '../components/TitreSection';
import CustomBox from '../components/CustomBox';
import { useParams } from 'react-router-dom';


const FicheManga = () => {
	const { id } = useParams();
	const [name, setName] = useState('');
	const [synopsis, setSynopsis] = useState('');
	const [type, setType] = useState('');
	const [etat, setEtat] = useState('');
	const [origine_annee, setOrigine_annee] = useState('');
	const [nb_tome_vo, setNb_tome_vo] = useState('');
	const [dessinateur, setDessinateur] = useState('');
	const [senariste, setSenariste] = useState('');
	const [editeur_VO, setEditeur_VO] = useState('');
	const [sortie_annee_francaise, setSortie_annee_francaise] = useState('');

	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
				try {
					const response = await fetch(url, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ page: 'fiche-manga', id : id }),
						credentials: 'include'
					});

					console.log("coucou");

					const result = await response.json();

					console.log(result);

					setName(result[0].titre);
					console.log(result[0].titre);
					setSynopsis(result[0].synopsis);
					setType(result[0].genre);
					setEtat(result[0].etat);
					setOrigine_annee(result[0].origine_annee);
					setNb_tome_vo(result[0].nb_tome_vo);
					if (result[0].dessinateur && result[0].dessinateur.length > 0) {
						setDessinateur(result[0].dessinateur[0].nom_auteur);
					} else {
						setDessinateur(''); // ou une autre valeur par défaut
					}
					if (result[0].senariste && result[0].senariste.length > 0) {
						setSenariste(result[0].senariste[0].nom_auteur);
					} else {
						setSenariste(''); // ou une autre valeur par défaut
					}
					setEditeur_VO(result[0].editeur_VO);
					setSortie_annee_francaise(result[0].sortie_annee_francaise);

					setData(result[0].editions);

				} catch (error) {
					console.error('Erreur lors de la récupération des données:', error);
				}
    };

    fetchData();
  }, [id]);


  return (
    <Container maxWidth="lg" sx={{ mb:4}}>
    <CustomBox sx={{
    	mt: 10
    }}>

        <TitrePrincipale>
          {name}
        </TitrePrincipale>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 10, }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              backgroundColor: '#F9A708',
              borderRadius: '4px',
              marginRight: '16px'
            }}>
              <i className="fas fa-book-open-reader" style={{ fontsize: '32px', color: '#FFFFFF'}}></i>
            </Box>


						<TitreSection>
							Synopsis
						</TitreSection>
          </Box>
        <Typography variant="body1" component="p" gutterBottom sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: '#0A2E52',
              textAlign: 'justify',
              textAlignLast: 'left',
              width: '100%',
            }}>
          <div dangerouslySetInnerHTML={{ __html: synopsis }} />
        </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 10, }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              backgroundColor: '#DB4F4F',
              borderRadius: '4px',
              marginRight: '16px'
            }}>
              <i className="fas fa-magnifying-glass" style={{ fontsize: '32px', color: '#FFFFFF'}}></i>
            </Box>
            <TitreSection>
							Infos
						</TitreSection>
          </Box>

		<Box sx={{ padding: '16px'}}>
      <Grid container spacing={2}>
        {/* Première colonne */}
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Type
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                {type}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                État
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}} >
              <Box display="flex" justifyContent="flex-end" >
                {etat === "Terminé" && <CompleteButton color="success" text="Terminé" />}                
              </Box>
            </Grid>


            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Origine (année)
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                Japon ({ origine_annee })
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                État (origine)
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                <CompleteButton color="success" text="Terminé" />
                <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52">
                  ({ nb_tome_vo } tomes)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        
        {/* Deuxième colonne */}
        <Grid item xs={12} sm={6} >
          <Grid container spacing={2} >
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Dessinateur
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                {dessinateur}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Scénariste
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                {senariste}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Éditeur VO
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                {editeur_VO}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Année
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                {sortie_annee_francaise}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>








</CustomBox>















      {data.length > 0 ? (
        data.map((collection, index) => (
          <Box sx={{ mt: 4 }}>
          <MangaCollection 
            key={collection.id}
            editor={collection.editeur} 
            title={collection.titre_edition} 
            volumes={collection.mangas}
          />
          </Box>
        ))
      ) : (
        <Typography>Loading...</Typography>
      )}



    </Container>
  );
};

export default FicheManga;