import { styled } from '@mui/system';
import Chip from '@mui/material/Chip';

const CustomChip = styled(Chip)(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: '16px 24px',
  height: '48px',
  borderRadius: '32px',
  '.MuiChip-icon': {
    marginLeft: theme.spacing(2),
  },
  '.MuiChip-label': {
    paddingRight: 0, // Enlever le padding à droite du label
    fontFamily: 'Poppins, sans-serif', // Définir la police Poppins
    fontWeight: 600, // Poids de la police
    fontSize: '16px', // Taille de la police
    lineHeight: '16px', // Hauteur de ligne
  }
}));

export default CustomChip;