import React from 'react';
import { Typography } from '@mui/material';


const TitreSection = ({ children, variant = 'h5', component = 'h2', gutterBottom = true, ...props }) => {
	return (
	<Typography
		variant={variant}
		component={component}
		sx={{
			fontFamily: 'Poppins, sans-serif',
			fontWeight: 600,
			fontSize: '32px',
			lineHeight: '48px',
			color: (theme) => theme.palette.text.primary,
			...props.sx,
		}}
		{...props}
		>
			{children}
		</Typography>
	);
}

export default TitreSection;



