// src/components/Navbar.js
import React from 'react';

import { Container, Grid, Typography, Box, Link as MuiLink  } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Logo } from '../assets/logo.svg';

import '../fonts/fonts.css';

export default function SimpleBottomNavigation() {

  const theme = useTheme();

  const logoStyle = {
    height: '60px', // Ajustez la taille du logo comme nécessaire
    width: 'auto',
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.nav.background, color: '#FFFFFF', padding: '20px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} mt={3}>
              <Logo style={logoStyle}/>
          </Grid>
          
          
        </Grid>
        <Box mt={7} sx={{display: 'flex',justifyContent: 'center', gap: 2 }}>
          <MuiLink href="https://www.gestionnaire-collection.com/statistique_general_manga/" color="inherit" underline="always" sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '12px',
            }}>Statistique du site</MuiLink>{' '}
					<MuiLink 
						component={Link} 
						to="/cgu"
						underline="none" 
						sx={{
							fontFamily: 'Poppins',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '12px',
							color: 'white',
						}}
					>
        C.G.U.
      </MuiLink>
          <MuiLink href="https://discord.gg/K3jnCv5mEY" color="inherit" underline="always" sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '12px',
            }}>Discord</MuiLink>{' '}

            <MuiLink 
							component={Link} 
							to="/historique-version/"
							underline="none" 
							sx={{
								fontFamily: 'Poppins',
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '12px',
								color: 'white',
							}}
						>
	        Alpha-0.05
	      </MuiLink>
          
        </Box>
        <Box mt={4} mb={4} textAlign="center" >
        	<Typography variant="body2" color="inherit" mt={1} sx={{
              fontFamily: 'Poppins',
              fontWeight: 200,
              fontSize: '16px',
              lineHeight: '12px',}}>
            Copyright © 2024 gestionnaire-collection.com | Tous droits réservés
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}