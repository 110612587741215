import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const url =
            process.env.NODE_ENV === 'development'
            ? 'http://gc/check_session.php'
            : '/api/check_session.php';

        const response = await axios.get(url,{
          withCredentials: true
        });
        //setIsAuthenticated(response.data.logged_in);
        if (response.data.success) {
          setIsAuthenticated(true);
          setUser({
            id: response.data.user_id,
            pseudo: response.data.user_nom,
            rank: response.data.user_rang
          });
        }
        else {
          setIsAuthenticated(false);
          setUser(null);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error checking auth:', error);
        setIsAuthenticated(false);
        setUser(null);
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, setIsAuthenticated, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };