import {Typography, Container, List, ListItem, ListItemText, Collapse, Link, Box, Grid  } from '@mui/material';
import CustomBox from '../components/CustomBox';
import CustomTypography from '../components/CustomTypography';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const CommentRangerSesManga = () => {

  const [open, setOpen] = useState({});

    const handleClick = (key) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [key]: !prevOpen[key],
    }));
  };



  const bulletStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '1em', // Ajout d'indentation à gauche de toute la ligne
	fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    color: '#0A2E52',
    '&::before': { // Ajout d'une pseudo-élément pour servir de puce
      content: '"•"', // Caractère de puce
      color: 'black', // Couleur de la puce
      fontWeight: 'bold', // Rendre la puce plus visible
      display: 'inline-block',
      width: '1em', // Espacement avant le texte
      marginLeft: '2em', // Alignement de la puce au bord
      marginRight: '0.5em' // Espace après la puce
    }
  };









	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
		<CustomBox sx={{
              mt: 4
            }}>
		<Typography variant="h5" component="h1" gutterBottom sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 700,
                  fontSize: '40px',
                  lineHeight: '40px',
                  mb: 2,
                  color: '#0A2E52',
                }}>
              Comment ranger ses mangas ?
            </Typography>
            
        	<CustomTypography>
		          	Lorsque votre collection de mangas commence à grandir, il devient nécessaire de trouver une solution de rangement plus organisée que de simplement les empiler dans un coin. Cet article n'est pas un guide strict, mais plutôt une source d'inspiration pour découvrir différentes façons de ranger vos mangas. Explorons ensemble mille et une méthodes pour organiser votre collection.
		    </CustomTypography>
           <CustomTypography>
		          	Avant de commencer, voici un sommaire car cet article est assez long !
		    </CustomTypography>
            </CustomBox>
			<CustomBox sx={{
				mt: 4
			}}>
				<Typography variant="h5" component="h1" gutterBottom sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 700,
					fontSize: '40px',
					lineHeight: '40px',
					mb: 2,
					color: '#0A2E52',
				}}>
					Sommaire
				</Typography>
				<List component="nav">
					<ListItem button onClick={() => handleClick('ranger_ordre')}>
						<ListItemText primary="Ranger ses mangas … dans l’ordre." />
							{open['ranger_ordre'] ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={open['ranger_ordre']} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<Link href="#ranger_ordre_alpha">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger par ordre alphabétique." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_editeur">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger par éditeur." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_taille">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger par taille." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_type">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger par type (cible démographique)." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_genre">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger par genre." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_portnawak">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger portnawak du moment qu’on s’y retrouve (et que ça fasse joli)." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_fini">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger séries en cours / séries finies." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_auteur">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger par auteur." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_derriere">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger derrière." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_retourner">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger retourné." />
									</ListItem>
								</Link>
								<Link href="#ranger_ordre_envers">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Ranger … à l’envers. (sens japonais)." />
									</ListItem>
								</Link>
							</List>
						</Collapse>

						<ListItem button onClick={() => handleClick('ranger_support')}>
							<ListItemText primary="Ranger ses mangas … les supports" />
								{open['ranger_support'] ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={open['ranger_support']} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<Link href="#ranger_dico">
										<ListItem button sx={{ pl: 4 }}>
											<ListItemText primary="Dico de Conforama." />
										</ListItem>
									</Link>
									<Link href="#ranger_billy">
										<ListItem button sx={{ pl: 4 }}>
											<ListItemText primary="Billy de chez Ikea" />
										</ListItem>
									</Link>
									<Link href="#ranger_besta">
										<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Besta de chez Ikea" />
										</ListItem>
									</Link>
									<Link href="#ranger_kallax">
										<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Kallax de chez Ikea" />
										</ListItem>
									</Link>
									<Link href="#ranger_planche_mural">
										<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Les « planches murales »" />
										</ListItem>
									</Link>
									<Link href="#ranger_mesure">
										<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Le sur-mesure" />
										</ListItem>
									</Link>
								</List>
							</Collapse>

							<ListItem button onClick={() => handleClick('ranger_simple_double')}>
								<ListItemText primary="Rangée de manga … simple ou double ?" />
								{open['ranger_simple_double'] ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={open['ranger_simple_double']} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<Link href="#ranger_simple_double">
										<ListItem button sx={{ pl: 4 }}>
											<ListItemText primary="Rangée de mangas… simple ou double ?" />
										</ListItem>
									</Link>
									<Link href="#ranger_boss_final">
										<ListItem button sx={{ pl: 4 }}>
											<ListItemText primary="Boss final" />
										</ListItem>
									</Link>
								</List>
							</Collapse>

							<ListItem button onClick={() => handleClick('ranger_idee')}>
								<ListItemText primary="Ranger ses mangas … Les idées en plus" />
								{open['ranger_idee'] ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
						<Collapse in={open['ranger_idee']} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<Link href="#ranger_anti_uv">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Film anti UV" />
									</ListItem>
								</Link>
								<Link href="#ranger_pochette">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Pochette plastique" />
									</ListItem>
								</Link>
								<Link href="#ranger_chevalet">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Chevalet" />
									</ListItem>
								</Link>
								<Link href="#ranger_figurine">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Figurine" />
									</ListItem>
								</Link>
								<Link href="#ranger_led">
									<ListItem button sx={{ pl: 4 }}>
										<ListItemText primary="Leds" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
					</List>
				</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger ses mangas… dans l’ordre
			</Typography>
			<CustomTypography>
				La première question à se poser est : comment trier ses mangas ? Avec une dizaine de mangas, il est facile de retrouver son préféré. Mais quand la collection atteint 400 volumes répartis sur plusieurs étagères et dans plusieurs pièces, cela devient plus compliqué. Examinons les différentes manières d'organiser tout cela.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_alpha" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger par ordre alphabétique
			</Typography>
			<CustomTypography>
				La méthode la plus basique et simple, qui ne demande pas de réflexion : on commence par A et on finit par Z. Facile, non ? Cependant, une question se pose : où ranger les titres qui commencent par des chiffres, comme "50 nuances de gras" ? La réponse est simple : avant les lettres. C'est parti !
			</CustomTypography>
			<CustomTypography>
				Mais attendez... ce n'est pas si simple ! Il existe plusieurs variantes à considérer :
			</CustomTypography>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Variante 1 : Les chiffres sont des lettres
			</Typography>
			<CustomTypography>
				La méthode la plus basique et simple, qui ne demande pas de réflexion : on commence par A et on finit par Z. Facile, non ? Cependant, une question se pose : où ranger les titres qui commencent par des chiffres, comme "50 nuances de gras" ? La réponse est simple : avant les lettres. C'est parti !
			</CustomTypography>
			<Typography variant="h5" component="h2" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 400,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				mt: 4,
				color: '#0A2E52',
			}}>
				Variante 2 : Les déterminants, c'est en option
			</Typography>
			<CustomTypography>
				Pour éviter d'avoir trop de titres à la lettre L, on peut ignorer les déterminants (le, la, les) lors du classement. Ainsi, au lieu de tout ranger sous L, on aurait :
			</CustomTypography>
			<List>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									"Les Carnets de l’apothicaire" classé à la lettre C pour "Carnets de l’apothicaire (les)"
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									"Le Chevalier d'Eon" classé à la lettre C pour "Chevalier d’Eon (le)"
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									"La logique des âmes" classé à la lettre L pour "Logique des âmes (la)"
								</Typography>
							}
						/>
					</Box>
				</ListItem>
			</List>
			<CustomTypography>
				L’ordre change, et l’emplacement aussi.
			</CustomTypography>
		</CustomBox>











<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_editeur" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger par éditeur
			</Typography>
			<CustomTypography>
				Pourquoi ne pas organiser vos mangas par éditeur ? Cette méthode présente deux avantages principaux.
			</CustomTypography>
			<CustomTypography>
				Le premier est esthétique. Bien que la taille standard des mangas soit d'environ 12 cm sur 18 cm, il existe de légères différences entre les éditeurs :
			</CustomTypography>
			<List>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Un manga de chez Kana (ex. Naruto) : 12 cm sur 17,5 cm
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Un manga de chez Glénat (ex. One Piece) : 11,5 cm sur 18 cm
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Un manga de chez Kazé Manga (ex. Mashle) : 11,4 cm sur 17,7 cm
								</Typography>
							}
						/>
					</Box>
				</ListItem>
			</List>
			<CustomTypography>
				Pour éviter ces variations, on peut ranger ensemble les mangas du même éditeur. Par exemple, One Piece et Sakamoto Days, tous deux édités par Glénat, ont exactement les mêmes dimensions. De plus, les éditeurs tendent à conserver une certaine cohérence graphique d’un manga à l’autre. Placer les mangas du même éditeur côte à côte permet de créer de jolis espaces dédiés à "Kana", "Glénat", "Ki-oon", etc.
			</CustomTypography>
			<CustomTypography>
				Le deuxième avantage concerne les « philosophies d’édition ». Avec un peu d’expérience, on peut remarquer que les choix éditoriaux des maisons d'édition ne sont pas aléatoires. Par exemple, mélanger des mangas de Glénat, qui publie principalement des shonen/shojo, avec des mangas de Ki-oon, qui se concentrent davantage sur le young-seinen, peut ne pas poser de problème majeur. Cependant, il est souvent préférable de ne pas mélanger ces types de mangas avec ceux de maisons d'édition spécialisées dans des genres très différents, comme Hana, très orientée vers le Yaoi (relations entre hommes), ou Hot Manga, spécialisée en Hentai (mangas pornographiques). Pour une meilleure organisation et pour respecter les publics cibles, il peut être judicieux de regrouper les mangas en fonction de leurs éditeurs et de leurs genres spécifiques : par exemple, les mangas de Hot Manga dans un endroit, les Yaoi de Hana dans un autre, etc.
			</CustomTypography>
		</CustomBox>




		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_taille" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger par taille
			</Typography>
			<CustomTypography>
				Nous avons déjà abordé brièvement cette méthode en parlant du rangement par éditeur, mais organiser ses mangas par taille est également une option intéressante. En effet, même au sein d'un même éditeur (comme Pika), les dimensions peuvent varier considérablement. Par exemple, l’édition standard de "L’attaque des titans" mesure 18,2 cm de haut, tandis que l’édition colossale de ce même manga atteint 26,5 cm de hauteur.
			</CustomTypography>
			<CustomTypography>
				Ranger les mangas par taille permet, d’une part, de maintenir une certaine harmonie visuelle sur vos étagères et, d’autre part, de mettre en valeur les éditions grand format. Ces dernières ont souvent tendance à être plus coûteuses, il est donc logique de leur accorder une place spéciale.
			</CustomTypography>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_type" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger par type (cible démographique)
			</Typography>
			<CustomTypography>
				Ranger vos mangas par type démographique est une autre méthode efficace. Placez les shonens avec les shonens, les shojos avec les shojos, et les seinens avec les seinens.
			</CustomTypography>
			<CustomTypography>
				Le principal intérêt de cette méthode est de pouvoir facilement trouver ce que vous cherchez en fonction de votre humeur. Par exemple, si vous avez envie de quelque chose d'un peu sombre, vous pouvez directement vous diriger vers la section des seinens.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_genre" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger par genre
			</Typography>
			<CustomTypography>
				Une autre méthode est de regrouper vos mangas par genre. Placez les mangas de fantasy ensemble, ceux de romance ensemble, et ceux de science-fiction ensemble. Par exemple, "Gunnm" (seinen) et "Astroboy" (shonen) peuvent se retrouver au même endroit car ce sont tous les deux des œuvres de science-fiction.
			</CustomTypography>
			<CustomTypography>
				Cette méthode permet également de retrouver des mangas selon votre humeur ou l'ambiance recherchée. Lorsque votre collection devient très grande, il est même possible de classer par sous-genre. Par exemple, dans la section "romance", vous pouvez sous-diviser en :
			</CustomTypography>
			<List>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Comédies romantiques
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Drames
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Yuri
								</Typography>
							}
						/>
					</Box>
				</ListItem>
			</List>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_portnawak" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger portnawak du moment qu’on s’y retrouve (et que ça fasse joli)
			</Typography>
			<CustomTypography>
				Cette méthode de rangement est souvent utilisée pour de petites collections, mais paradoxalement, elle peut aussi être adoptée pour de grandes collections.
			</CustomTypography>
			<CustomTypography>
				Pour les petites collections, l'idée est de retrouver facilement ses mangas tout en les mettant en valeur. Parfois, une petite collection de 30 mangas peut refléter bien plus de passion qu'une bibliothèque en contenant 1200.
			</CustomTypography>
			<CustomTypography>
				Pour les grandes collections, cela peut arriver quand on commence à manquer d'étagères. On se retrouve alors à poser des mangas au-dessus de la bibliothèque, à côté, par terre en piles, ou sur la table de nuit, qui contient souvent dans ses tiroirs les 26 mangas que l’on prévoit de lire depuis 6 mois. Parfois, on range les mangas au fur et à mesure qu’on les achète, car finalement... ranger, c'est du temps en moins pour lire.
			</CustomTypography>
			<CustomTypography>
				Certaines personnes vont jusqu'à créer un fichier Excel pour référencer tous leurs mangas, en indiquant même leur emplacement physique précis. D'autres n'aiment pas trop voir le bandeau vert de Glénat, alors ils dispersent ces mangas un peu partout. Bref... c'est du rangement portnawak.
			</CustomTypography>
			<CustomTypography>
				PS : Avec ce type de rangement, il n'est pas rare de passer 10 minutes à chercher le manga souhaité...
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_fini" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger séries en cours / séries finies
			</Typography>
			<CustomTypography>
				Un autre type de rangement consiste à séparer les séries en cours des séries terminées. Cette méthode peut impliquer deux espaces distincts (qui peuvent évidemment se trouver dans la même pièce).
			</CustomTypography>
			<CustomTypography>
				D'un côté, vous avez les mangas terminés, que vous n'avez plus besoin de manipuler. De l'autre, vous avez les séries en cours, qui évoluent au fil des nouvelles parutions. Lorsque le dernier tome d'une série en cours est publié, il rejoint alors l'espace des séries terminées.
			</CustomTypography>
			<CustomTypography>
				L'objectif de cette méthode est de gérer l'évolution de votre collection. Avec le temps, vous accumulez de plus en plus de séries terminées (sauf si vous avez l'habitude d'acheter de nombreux premiers tomes parce que "ça a l'air chouette"). Lorsque de nouveaux tomes sortent, il est parfois nécessaire de réorganiser l'espace pour les intégrer à côté des volumes existants. Si les séries terminées sont mélangées avec les séries en cours, cela peut entraîner des réarrangements fréquents et fastidieux, surtout si vous achetez des mangas chaque semaine. Cette méthode permet d'éviter de devoir déplacer des centaines voire des milliers de mangas chaque semaine.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_auteur" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger par auteur
			</Typography>
			<CustomTypography>
				Une méthode couramment utilisée pour les bandes dessinées consiste à ranger les œuvres par auteur. Certains mangakas (auteurs de mangas) développent au fil des ans une base de fans qui souhaitent regrouper toutes leurs œuvres en un seul endroit. L'un des exemples les plus célèbres est probablement Junji Ito, spécialisé dans les mangas d'horreur et perturbants, avec un style de dessin très particulier et facilement reconnaissable.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_derriere" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger derrière
			</Typography>
			<CustomTypography>
				Qu'est-ce que cela signifie de ranger "derrière" ou "devant" ? Ce concept sera développé plus en détail plus loin, notamment en ce qui concerne le choix entre une simple rangée et une double rangée. Si vous optez pour une double rangée, il est possible de placer les grandes collections de mangas à l'arrière.
			</CustomTypography>
			<CustomTypography>
				Par exemple, vous pouvez n'afficher que les tomes 1, 2, 3, 4 et 5 de "One Piece" à l'avant, et placer les 95 autres tomes à l'arrière, derrière d'autres types de mangas. Étant donné que la collection est suffisamment grande, elle occupera tout le rayon de derrière. Il sera alors facile de se souvenir que "les One Piece sont derrière".
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_retourner" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger retourné
			</Typography>
			<CustomTypography>
				Retourner la tranche de certains mangas... pourquoi faire ça ? Cette méthode est utilisée par les champions du "je l’achète et je le lirai plus tard". Une tranche retournée signifie que le manga n'a pas encore été lu. C’est très visuel : en parcourant votre mangathèque, vous remarquez immédiatement les mangas non lus et vous pouvez facilement choisir "tiens, je lirai bien la suite de ça".
			</CustomTypography>
			<CustomTypography>
				Pour ceux qui aiment l’idée mais qui n’aiment pas retourner leurs mangas, il existe des gestionnaires de collection, comme MangaCollec et notre propre site web, qui offrent cette fonctionnalité. Que ce soit sur notre site ou via notre application, vous pouvez facilement indiquer quels mangas ont été lus et lesquels ne le sont pas encore.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_ordre_envers" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger… à l’envers (sens japonais)
			</Typography>
			<CustomTypography>
				Cela signifie ranger les tomes par numéros décroissants : 5, 4, 3, 2, 1.
			</CustomTypography>
			<CustomTypography>
				Eh oui… ces japonais ne font jamais rien comme tout le monde ! Ils lisent de droite à gauche... ils mangent du poisson fugu... ils transforment même le rangement en défi culturel !
			</CustomTypography>
			<CustomTypography>
				En réalité, cela ne gêne pas dans 99 % des cas. Cependant, certains mangas ont des frises sur leurs tranches qui nécessitent un rangement par ordre croissant pour être correctement alignées. Il est important de noter que même pour les mangas en japonais, les frises imposent un rangement de gauche à droite, ce n'est donc pas une fantaisie occidentale.
			</CustomTypography>
			<CustomTypography>
				Certaines sources non fiables (ce qui ne signifie pas nécessairement qu'elles sont fausses) suggèrent que certains mangas au Japon doivent être rangés de droite à gauche. Alors, comme pour un ami indécis sur l'achat d'un nouveau jean, faites comme vous le sentez.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger ses mangas… les supports
			</Typography>
			<CustomTypography>
				Ah, c’est bien beau de parler de méthodes de rangement, mais il va falloir les poser quelque part. Quelques petites précisions : les marques et les liens mentionnés ici ne sont absolument pas sponsorisés et il n’y a aucun lien d’affiliation. Ce n’est qu’un récapitulatif d’expériences vécues et/ou racontées. (Bon, après, si je peux recevoir un chèque, je ne dis pas non non plus.)
			</CustomTypography>
			<CustomTypography>
				Autre précision importante, car il est possible que vous ayez accès à d’autres meubles, anciens ou de brocantes. Les bibliothèques d’une largeur de plus de 60 cm, notamment celles de 80 cm, ont tendance à se courber avec le temps. La casse est très rare, mais le résultat est assez inesthétique. À l’inverse, les bibliothèques étroites de 40 cm peuvent être très résistantes et supporter beaucoup de poids : manuels scolaires, romans, BD, etc. Ces remarques s'appliquent à toutes les bibliothèques, toutes marques confondues. (Bon d’accord… peut-être pas pour une bibliothèque en chêne massif de 50 cm d’épaisseur…)
			</CustomTypography>
			<CustomTypography>
				Nous allons également parler des coûts de stockage. Parfois, l’achat d’un meuble peut sembler cher, mais quand on compare ce coût au prix de ce qu’il contiendra, on peut avoir des surprises. Par exemple, une bibliothèque à 1000 euros (oui, oui…) pouvant contenir 10 000 mangas revient à dépenser 10 centimes de plus par manga acheté (si l’on considère qu’un manga coûte environ 7 euros). Alors, est-ce cher ou pas cher ? À vous de juger.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_dico" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Dico de Conforama
						</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/dico.png"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Commençons avec la Dico de chez Conforama.
					</CustomTypography>
					<CustomTypography>
						Cette étagère existe en deux largeurs : 40 cm et 80 cm, avec une profondeur de 29,5 cm. C’est un choix assez économique, surtout pour la version de 80 cm. Elle dispose d'une planche vissée au milieu pour renforcer la structure, et de 4 séries de 4 trous pour ajuster légèrement la hauteur des étages, permettant ainsi d'obtenir un total de 6 étages.
					</CustomTypography>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							mt: 4,
							color: '#0A2E52',
						}}>
							Coût de stockage :
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Version 40 cm
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 49,99€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée simple (une seule rangée de mangas par étage) : Environ 22 mangas par étage, soit 132 mangas au total, avec un coût de stockage de 38 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée double (deux rangées de mangas par étage) : Environ 44 mangas par étage, soit 264 mangas au total, avec un coût de stockage de 19 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Version 80 cm
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 69,99€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée simple : Environ 44 mangas par étage, soit 264 mangas au total, avec un coût de stockage de 27 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée double : Environ 88 mangas par étage, soit 528 mangas au total, avec un coût de stockage de 13 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_billy" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Billy de chez Ikea
						</Typography>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Véritable star des bibliothèques à petit prix, une Billy est vendue toutes les 5 secondes. On peut dire que c’est un produit phare. Elle offre 6 étages délimités par 5 planches, dont une fixe, avec une profondeur de 30 cm, comme la Dico. La grande différence est que les trous permettant de placer les planches sont réalisés sur toute la hauteur du meuble, rendant ainsi la bibliothèque beaucoup plus modulable, notamment avec de nouvelles planches, des portes vitrées, etc. Nous allons voir 3 modèles différents, dont un d’angle… vous savez, ces endroits difficiles à exploiter dans une pièce.
					</CustomTypography>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							mt: 4,
							color: '#0A2E52',
						}}>
							Coût de stockage :
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Version 40 cm
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 39,99€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée simple : Environ 22 mangas par étage, soit 132 mangas au total, avec un coût de stockage de 30 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée double : Environ 44 mangas par étage, soit 264 mangas au total, avec un coût de stockage de 15 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Version 80 cm
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 59,99€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée simple : Environ 44 mangas par étage, soit 264 mangas au total, avec un coût de stockage de 22 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée double : Environ 88 mangas par étage, soit 528 mangas au total, avec un coût de stockage de 11 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Version angle (coin d’une pièce)
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 124,97€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée simple : Environ 66 mangas par étage, soit 396 mangas au total, avec un coût de stockage de 32 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Rangée double : Environ 132 mangas par étage, soit 792 mangas au total, avec un coût de stockage de 16 centimes par manga
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Il existe de nombreuses versions de ce meuble, impossible de toutes les présenter. Il y a des Billy de 2m30 au lieu de 2m, avec des portes vitrées, des tiroirs, etc. Bref, tout un monde de possibilités.
					</CustomTypography>
				</Grid>
				
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/billy.jpg"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_besta" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Besta de chez Ikea
						</Typography>
				</Grid>


				
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/besta.png"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>

				<Grid item xs={12} md={8}>
					<CustomTypography>
						Coup de cœur personnel. Les Besta sont tout simplement des Billy plus profondes (40 cm au lieu de 30 cm) et semblent plus robustes. Attention ! Je ne remets pas en cause la solidité des Billy, mais les Besta étant plus étroites (60 cm au lieu de 80 cm), elles sont donc mécaniquement plus solides. Elles sont cependant plus chères.
					</CustomTypography>
					<CustomTypography>
						Leur profondeur prend tout son sens en rangement « double rangée » ou en rangement « avec des goodies devant ». Nous reviendrons sur ces manières de mettre en valeur notre bibliothèque plus tard.
					</CustomTypography>
					<CustomTypography>
						Nous nous intéresserons exclusivement à la version de 60 cm de large, 40 cm de profondeur et 192 cm de hauteur. Sachez qu'il existe 8 modèles différents en tout, notamment un module de 38 cm de haut et de 120 cm de large à placer au-dessus de deux autres Besta (ou suspendu au mur).
					</CustomTypography>
					<CustomTypography>
						Enfin, parmi ces versions, il y en a encore d’autres de 20 cm de profondeur. Mais nous : 60x40x192 !
					</CustomTypography>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							mt: 4,
							color: '#0A2E52',
						}}>
							Coût de stockage :
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Rangée simple
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 125€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Environ 33 mangas par étage, soit 198 mangas au total, avec un coût de stockage de 63 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Rangée double
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 150€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Environ 66 mangas par étage, soit 396 mangas au total, avec un coût de stockage de 37 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Pourquoi le prix est-il différent entre la simple et la double rangée ? Parce que des trous et des tablettes spécifiques existent pour cet usage, ce qui en fait une bibliothèque particulièrement pratique. Le contrecoup est malheureusement son coût de stockage, qui est plus de trois fois supérieur à celui d'une Billy.
					</CustomTypography>
					<CustomTypography>
						Cependant, rappelez-vous : au lieu d’acheter un manga à 7 euros, vous l’achetez à 7,37 euros, et vous avez votre belle Besta (oui, la personne qui écrit cet article est un fanboy de ce modèle).
					</CustomTypography>
					<CustomTypography>
						Sachez également que les tablettes peuvent être en verre.
					</CustomTypography>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_kallax" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Kallax de chez Ikea
						</Typography>
				</Grid>


				<Grid item xs={12} >
					<CustomTypography>
						Aussi profonde qu’une Besta, mais avec des compartiments carrés de taille fixe, les Kallax permettent de personnaliser chaque case avec une ambiance différente, un manga particulier, voire une fonctionnalité spécifique à UNE case… Oui, vous pouvez même mettre une porte à une des cases pour dissimuler votre collection d’Hentai importée directement du Japon !
					</CustomTypography>
					<CustomTypography>
						Il existe plus de 7 modèles différents, mais nous nous concentrerons sur la version de 182 cm sur 182 cm.
					</CustomTypography>


					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							mt: 4,
							color: '#0A2E52',
						}}>
							Coût de stockage :
						</Typography>
					</Grid>

					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 125€
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Rangée simple
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Environ 90 mangas par étage, soit 450 mangas au total, avec un coût de stockage de 44 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>

					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Rangée double
						</Typography>
					</Grid>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Environ 180 mangas par étage, soit 900 mangas au total, avec un coût de stockage de 22 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Et oui, le prix est élevé, mais le coût par manga n’est pas si grand que ça.
					</CustomTypography>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_planche_mural" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Les « planches murales »
						</Typography>

					<CustomTypography>
						Il n'y a pas de marque en particulier pour ces étagères murales, il en existe plusieurs modèles, notamment avec des fixations cachées. Prenons par exemple une étagère de 80 cm de large.
					</CustomTypography>


					<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							mt: 4,
							color: '#0A2E52',
						}}>
							Coût de stockage :
						</Typography>
					</Grid>

					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Prix : 15 euros
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Environ 44 mangas, soit un coût de stockage de 34 centimes par manga.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Et voilà, c’est tout. Son avantage ? On peut les installer partout ! Au-dessus du lit, du bureau, dans le salon… Elles ont l’avantage d'avoir de nombreux modèles esthétiques « de base ». Là où la beauté d’une bibliothèque réside souvent dans les éléments qu’on lui apporte, les planches murales offrent un aspect décoratif immédiat.
					</CustomTypography>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_mesure" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Le sur-mesure
						</Typography>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Sous-catégorie 1 : Le sur-mesure fait soi-même
						</Typography>
						<CustomTypography>
							Réaliser une étagère sur-mesure permet de créer un meuble exactement à la hauteur, la largeur et la solidité souhaitées, selon vos besoins spécifiques.
						</CustomTypography>


						<Box
							component="img"
							src="https://www.gestionnaire-collection.com/image/vig_fini.webp"
							alt="Example"
							sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
						/>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Sous-catégorie 2 : Le sur-mesure fait par un artisan
						</Typography>
						<CustomTypography>
							C'est la même idée que le sur-mesure fait soi-même, mais réalisé par un professionnel. Bien que ce soit beaucoup plus cher, cela offre des possibilités rarement disponibles dans le commerce, comme des formes particulières et non simplement des lignes droites.
						</CustomTypography>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '28px',
							lineHeight: '28px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Sous-catégorie 3 : Le semi-sur-mesure
						</Typography>
						<CustomTypography>
							Qu'est-ce que c'est ? Certains fabricants permettent de spécifier exactement les dimensions souhaitées, en termes de longueur, hauteur et profondeur, à partir d'une base. Ils se chargent uniquement de découper les planches aux dimensions demandées. Cela peut être un bon compromis lorsque vous avez, par exemple, 48,3 cm entre une bibliothèque existante et le mur.
						</CustomTypography>
				</Grid>
			</Grid>
		</CustomBox>










		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h1" id="ranger_simple_double" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 700,
				fontSize: '40px',
				lineHeight: '40px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Rangée de mangas… simple ou double ?
			</Typography>
			<CustomTypography>
				Aaaah… voici un sujet qui fait souvent débat : faut-il ranger les mangas en une seule rangée pour tous les voir, ou opter pour une double rangée afin de gagner de la place ? Il n’y a pas de bonne ou de mauvaise réponse, c’est avant tout une question de goût personnel.
			</CustomTypography>
			<Typography variant="h5" component="h3" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Rangée simple
			</Typography>
			<CustomTypography>
				Il n’y a pas 500 manières de poser ses mangas sur une seule rangée, mais examinons ses avantages :
			</CustomTypography>
			<List>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Pratique : On n'a pas besoin de déplacer un manga pour en prendre un autre.
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Esthétique : Il est très facile de trier ses mangas par hauteur.
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Pratique et esthétique : Il est plus facile de retrouver ses mangas, surtout les petites séries, car elles ne sont pas partiellement cachées par d'autres.
								</Typography>
							}
						/>
					</Box>
				</ListItem>
			</List>

			<CustomTypography>
				Et son principal défaut :
			</CustomTypography>
			<List>
				<ListItem disablePadding>
					<Box component="div" sx={bulletStyle}>
						<ListItemText 
							primary={
								<Typography component="span">
									Espace : Prend plus de place et coûte plus cher à stocker.
								</Typography>
							}
						/>
					</Box>
				</ListItem>
			</List>

			<Typography variant="h5" component="h3" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Rangée double
			</Typography>

			<CustomTypography>
				Passons maintenant à la double rangée. Il existe de nombreuses astuces et méthodes pour organiser une double rangée de mangas. Voyons ces méthodes :
			</CustomTypography>
		</CustomBox>
		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h3" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Double rangée simple
			</Typography>

			<CustomTypography>
				Pas de chichi : on place les mangas qu’on veut moins voir derrière. Ils sont cachés et on ne les voit plus. Fin de l’histoire.
			</CustomTypography>
		</CustomBox>
		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h3" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Double rangée surélevée
			</Typography>

			<CustomTypography>
				Comment obtenir cet effet ? Il existe de nombreuses manières :
			</CustomTypography>
		</CustomBox>



		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							La moins chère : avec des boîtes de mouchoirs ou de figurines POP
						</Typography>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Utiliser des boîtes de mouchoirs ou de figurines POP est une solution économique et simple.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Gratuit
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Taille adéquate
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Peu esthétique
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Pas très solide
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/rangement_pop.jpg"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>
			</Grid>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							La moins chère bis : Les rouleaux de papier toilette
						</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/image/rangement_pq.webp"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Cette méthode est plus solide et moins absurde qu’elle n’en a l’air. Les rouleaux sont positionnés à la verticale, avec une fine planche au-dessus.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Gratuit (hors coût de la planche)
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Assez solide
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Bah… ce sont des rouleaux de papier toilette…
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Il faut en mettre suffisamment pour ne pas déséquilibrer la planche
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</CustomBox>


		<CustomBox sx={{
			mt: 4
		}}>
			
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Des mangas, toujours plus de mangas
						</Typography>
				
					<CustomTypography>
						Empiler des mangas à l’horizontale les uns sur les autres jusqu’à la hauteur souhaitée.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Permet de ranger encore plus de mangas dans un espace donné
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											C'est une grosse galère quand on veut précisément un manga de cette pile
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Les hauteurs ne sont pas uniformes (les mangas n’ont pas la même largeur)
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Forte possibilité d’abîmer les mangas, surtout quand on en ajoute ou en retire
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							La plus classe : une planche moins profonde, surélevée
						</Typography>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Très esthétique
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Assez solide
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Peu coûteux
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Très facile à mettre en place, surtout quand le meuble est conçu pour
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Permet de cacher ses mangas inavouables sous la planche... ou sa collection de marque-pages
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Nécessite un peu de bricolage pour percer les trous droits si ceux-ci ne sont pas déjà présents
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="	https://www.gestionnaire-collection.com/dr_planche.jpg"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h3" gutterBottom sx={{
						fontFamily: 'Poppins, sans-serif',
						fontWeight: 600,
						fontSize: '32px',
						lineHeight: '32px',
						mb: 2,
						color: '#0A2E52',
					}}>
						La plus chère et la bien trop complexe : avec deux gros tasseaux de 76*66 de section collés ensemble
					</Typography>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Incassable
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Très (trop) coûteux
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Pas très esthétique
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Nécessite des outils (scie, cale à poncer, pistolet à colle)
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Lourd
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/dr_tasseau.jpg"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h3" id="ranger_boss_final" gutterBottom sx={{
						fontFamily: 'Poppins, sans-serif',
						fontWeight: 600,
						fontSize: '32px',
						lineHeight: '32px',
						mb: 2,
						color: '#0A2E52',
					}}>
						Boss final
					</Typography>
				</Grid>


				<Grid item xs={12}>
					<CustomTypography>
						Bon, si deux rangées ne suffisent pas...
					</CustomTypography>
				</Grid>
				 <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} md={10} lg={6}>
            <Box
              component="img"
              src="https://www.gestionnaire-collection.com/tr_boss_final.jpg"
              alt="Example"
              sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
			</Grid>
		</CustomBox>
		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h3" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Ranger ses mangas… Les idées en plus
			</Typography>

			<CustomTypography>
				En plus des méthodes traditionnelles de rangement, il existe de nombreuses astuces et idées créatives pour organiser votre collection de mangas. Que ce soit pour optimiser l’espace, mettre en valeur vos œuvres préférées ou simplement ajouter une touche personnelle à votre bibliothèque, ces idées peuvent transformer votre rangement en une véritable œuvre d’art. Explorons quelques-unes de ces suggestions innovantes.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_anti_uv" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Film anti-UV
						</Typography>
				</Grid>


				<Grid item xs={12}>
					<CustomTypography>
						Protégez vos mangas du jaunissement en améliorant votre bibliothèque avec des films anti-UV. Le jaunissement des pages est principalement causé par les rayons UV du soleil. La première étape, gratuite, est de ne pas entreposer vos mangas face à une fenêtre. La seconde consiste à appliquer des filtres UV sur les fenêtres pour laisser passer la lumière tout en bloquant les UV.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											S’applique à toutes les fenêtres
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Ne coûte qu’une dizaine d’euros
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Réduit légèrement la luminosité de la pièce
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Variante : les filtres UV intégrés
						</Typography>
				</Grid>


				<Grid item xs={12}>
					<CustomTypography>
						Il existe également des fenêtres avec traitement anti-UV intégré que les constructeurs peuvent appliquer directement.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Aucune perte de luminosité (comme les vitres d’une voiture)
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Plus efficace que les films anti-UV (bien que ces derniers soient déjà très efficaces)
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Nécessite de changer de fenêtre...
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Coût élevé, entre 1000 et 2000 euros par fenêtre
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</CustomBox>
		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_pochette" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Pochette plastique
						</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						component="img"
						src="https://www.gestionnaire-collection.com/pochette_1.jpg"
						alt="Example"
						sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
					/>
				</Grid>


				<Grid item xs={12} md={8}>
					<CustomTypography>
						Les pochettes plastiques transparentes sont principalement utilisées pour protéger vos mangas de la poussière, des rayures et de l’humidité.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Protection physique contre tous types d’agressions
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Coût : environ 10 euros pour 100 pochettes
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Peut être assez inesthétique, surtout si mal appliqué
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</CustomBox>



		<CustomBox sx={{
			mt: 4
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h5" component="h3" id="ranger_chevalet" gutterBottom sx={{
							fontFamily: 'Poppins, sans-serif',
							fontWeight: 600,
							fontSize: '32px',
							lineHeight: '32px',
							mb: 2,
							color: '#0A2E52',
						}}>
							Chevalet
						</Typography>
				</Grid>


				<Grid item xs={12}>
					<CustomTypography>
						Le chevalet est probablement l’élément qui met le plus facilement en valeur un manga particulier, un collector ou encore un ex-libris.
					</CustomTypography>
					<CustomTypography>
						Point forts : 
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Très esthétique et peut se placer n’importe où : devant, à côté, etc.
										</Typography>
									}
								/>
							</Box>
						</ListItem>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Très abordable par rapport à la plus-value (environ 1 euro le chevalet)
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
					<CustomTypography>
						Points faibles :
					</CustomTypography>
					<List>
						<ListItem disablePadding>
							<Box component="div" sx={bulletStyle}>
								<ListItemText 
									primary={
										<Typography component="span">
											Hum…
										</Typography>
									}
								/>
							</Box>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</CustomBox>
		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h3" id="ranger_figurine" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Figurine
			</Typography>

			<CustomTypography>
				Les figurines peuvent être placées devant les mangas, à côté ou au-dessus de la bibliothèque pour mettre en valeur votre collection. Bien agencées, elles ajoutent une touche personnelle et esthétique. Attention toutefois, si elles sont placées sur les rangs du bas, il faudra aimer faire la poussière très régulièrement.
			</CustomTypography>
		</CustomBox>

		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h3" id="ranger_led" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				LEDs
			</Typography>

			<CustomTypography>
				Les LEDs sont à la fois un élément de décoration et un outil utilitaire. Elles permettent de créer une ambiance changeante avec des couleurs variées ou d’éclairer vos mangas. Vous pouvez utiliser des bandeaux de LEDs ou de petites lumières alimentées par piles.
			</CustomTypography>
		</CustomBox>
		<CustomBox sx={{
			mt: 4
		}}>
			<Typography variant="h5" component="h3" gutterBottom sx={{
				fontFamily: 'Poppins, sans-serif',
				fontWeight: 600,
				fontSize: '32px',
				lineHeight: '32px',
				mb: 2,
				color: '#0A2E52',
			}}>
				Conclusion
			</Typography>

			<CustomTypography>
				Vous avez tout lu ? J’espère bien ! Comprenez qu’il ne s’agit pas d’un guide à suivre à la lettre, mais d’une banque d’idées. Votre bibliothèque doit avant tout vous plaire ; si elle plaît aux autres, c’est un bonus.
			</CustomTypography>
		</CustomBox>







































</Container>
)}

export default CommentRangerSesManga;