import { Container,Typography} from '@mui/material';
import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import CustomTypography from '../components/CustomTypography';
import SousTitre from '../components/SousTitre';
import RepartitionParType from '../components/RepartitionParType';
import RepartitionParEditeur from '../components/RepartitionParEditeur';
import RepartitionParStatus from '../components/RepartitionParStatus';
import CustomChip from '../components/CustomChip';
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';

const MangaSatistiquePerso = () => {
	const [nombre_de_manga, setNombre_de_manga] = useState('');
	const [nombre_de_manga_lu, setNombre_de_manga_lu] = useState('');
	const [valeur_total, setValeur_total] = useState('');
	const [message_perso, setMessage_perso] = useState('');
	const [poid_total, setPoid_total] = useState('');
	const [eppaisseur_total, setEppaisseur_total] = useState('');
	const [message_perso_taille, setMessage_perso_taille] = useState('');
	const [nombre_cette_annee, setNombre_cette_annee] = useState('');
	const [nombre_partie_mangator, setNombre_partie_mangator] = useState('');

	const [repartitionParType, setRepartitionParType] = useState([]);
	const [repartitionParTypeNbTome, setRepartitionParTypeNbTome] = useState([]);

	const [repartitionParEditeur, setRepartitionParEditeur] = useState([]);
	const [repartitionParEditeurNbTome, setRepartitionParEditeurNbTome] = useState([]);

	const [repartitionStatusManga, setRepartitionStatusManga] = useState([]);
	const [moyennePrixPerso, setMoyennePrixPerso] = useState([]);

	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ page: 'MangaSatistiquePerso' }),
					credentials: 'include'
				});

				//console.log(event.target.checked)
				//console.log(response.text());

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				console.log(result);
				setNombre_de_manga (result.nombre_de_manga);
				setNombre_de_manga_lu (result.nombre_de_manga_lu);
				setValeur_total (result.valeur_total.toFixed(2));
				setMessage_perso (result.message_perso);
				setPoid_total (result.poid_total.toFixed(2));
				setEppaisseur_total (result.eppaisseur_total.toFixed(2));
				setMessage_perso_taille (result.message_perso_taille);
				setNombre_cette_annee (result.nombre_cette_annee);
				setRepartitionParType (result.repartition_type);
				setRepartitionParTypeNbTome (result.repartition_type_nb_tome);
				setRepartitionParEditeur (result.repartition_editeur_collecton);
				setRepartitionParEditeurNbTome (result.repartition_editeur_nb_tome);
				setRepartitionStatusManga (result.repartition_status_manga);
				console.log(result.repartition_status_manga);
				setNombre_partie_mangator(result.nombre_partie_mangator);
				setMoyennePrixPerso((result.valeur_total / result.nombre_de_manga).toFixed(2));

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};

		fetchDonnees();
	}, []);

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			<CustomBox sx={{mt: 10}}>
				<TitrePrincipale>Statistique</TitrePrincipale>
				<SousTitre>Nombre de manga</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{nombre_de_manga}</Typography>
				<SousTitre>Progression lecture</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{nombre_de_manga_lu}/{nombre_de_manga}</Typography>
				<SousTitre>Valeur de la collection</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{valeur_total} €</Typography>
				<CustomTypography  sx={{ textAlign: 'center'}}>{message_perso}</CustomTypography>
				<SousTitre>Prix par manga en moyenne</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{moyennePrixPerso} €</Typography>
				<CustomTypography  sx={{ textAlign: 'center'}}>{message_perso}</CustomTypography>
				<SousTitre>Poids de la collection</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{poid_total} kg</Typography>
				<SousTitre>Hauteur de la collection</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{eppaisseur_total} m</Typography>
				<CustomTypography  sx={{ textAlign: 'center'}}>{message_perso_taille}</CustomTypography>
				<SousTitre>Nombre d'ajout cette année</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{nombre_cette_annee}</Typography>
				<SousTitre>Type (par nombre de collection)</SousTitre>
					<RepartitionParType repartitionData={repartitionParType} />
				<SousTitre>Type (par nombre de tome)</SousTitre>
					<RepartitionParType repartitionData={repartitionParTypeNbTome} />
				<SousTitre>Éditeurs favoris (par collections)</SousTitre>				
					<RepartitionParEditeur repartitionData={repartitionParEditeur} />
				<SousTitre>Éditeurs favoris (par tomes)</SousTitre>
					<RepartitionParEditeur repartitionData={repartitionParEditeurNbTome} />
				<SousTitre>Status de mes mangas</SousTitre>
					<RepartitionParStatus repartitionData={repartitionStatusManga} />
				<SousTitre>Nombre de partie de mangator réalisé</SousTitre>
				<Typography sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 400,
					fontSize: '28px',
					lineHeight: '28px',
					//color: '#0A2E52',
					textAlign: 'center',
					mt: 2,
					mb:4
				}}>{nombre_partie_mangator}</Typography>
				<CustomChip component={Link} to="/recap-manga-perso-achat/" color="primary" label="RECAP achat" clickable="true" />
				<CustomChip component={Link} to="/recap-manga-perso-lecture/" color="primary" label="RECAP lecture" clickable="true" />
			</CustomBox>
		</Container>
	)
}

export default MangaSatistiquePerso;