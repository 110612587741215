import React, { useState, useEffect } from 'react';
import { AppBar, Button, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const MangaNavBar = ({ isVisible }) => {
	const [upcomingReleases, setUpcomingReleases] = useState(0);

  useEffect(() => {
    if (isVisible) {
    	console.log("on test");
			const fetchDonnees = async () => {
				const url =
					process.env.NODE_ENV === 'development'
						? 'http://gc/info-page.php'
						: '/api/info-page.php';
				try {
					const response = await fetch(url, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ page: 'nb_manga_prochaine_sortie' }),
						credentials: 'include'
					});

					//console.log(event.target.checked)
					//console.log(response.text());

					if (!response.ok) throw new Error('Réponse réseau non réussie');

					const result = await response.json();

					setUpcomingReleases(result.nombre_prochaines_sorties);

				} catch (error) {
					console.error('Erreur lors de la récupération des données:', error);
				}
			};
			fetchDonnees();
    }
  }, [isVisible]);

	if (!isVisible) return null;

	return (
	<AppBar position="static"  sx={{ height: 64,
	backgroundColor: '#8086E2', }}>
		<Container maxWidth="lg">
			<Box
			sx={{
				display: 'flex',
				overflowX: 'auto',
				whiteSpace: 'nowrap',
				width: '100%',
				'& > *': {
				flexShrink: 0, // Empêche le rétrécissement des boutons pour éviter le chevauchement
				},
					'&::-webkit-scrollbar': {
					height: '6px',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#888',
					borderRadius: '10px',
				},
				'&::-webkit-scrollbar-thumb:hover': {
					backgroundColor: '#555',
				},
			}}
			>
				<Button sx={{ lineHeight: '46px', }} color="inherit" component={Link} to="/accueil-manga/" >ACCUEIL MANGA</Button>
				<Button color="inherit" component={Link} to="/manga-personnel/" >MES MANGA</Button>
				<Button color="inherit" component={Link} to="/manga-statistique-perso/" >STATISTIQUE</Button>
				<Button color="inherit" component={Link} to="/prochaine-sortie-perso/" >PROCHAINE SORTIE ({upcomingReleases})</Button>
				<Button color="inherit" component={Link} to="/manga-personnel-couverture/" >MES MANGA (COUVERTURE)</Button>
				<Button color="inherit" component={Link} to="/manga-pal-perso/" >PAL</Button>
				<Button color="inherit" component={Link} to="/manga-manquant-perso/" >MANQUANT</Button>
				<Button color="inherit" component={Link} to="/manga-envie-perso/" >ENVIE</Button>
			</Box>
		</Container>
	</AppBar>
	)
};

export default MangaNavBar;