// src/sections/Home.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Chip, styled,Grid } from '@mui/material';
import axios from 'axios';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CustomBox from '../components/CustomBox';
import TuileArticle from '../components/TuileArticle';

const CustomChip = styled(Chip)(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: '16px 24px',
  height: '48px',
  borderRadius: '32px',
  '.MuiChip-icon': {
    marginLeft: theme.spacing(2),
  },
  '.MuiChip-label': {
    paddingRight: 0, // Enlever le padding à droite du label
    fontFamily: 'Poppins, sans-serif', // Définir la police Poppins
    fontWeight: 600, // Poids de la police
    fontSize: '16px', // Taille de la police
    lineHeight: '16px', // Hauteur de ligne
  }
}));

const Home = () => {

const [liste_article_site, setListe_article_site] = useState('');

const [liste_article_actu_site, setListe_article_actu_site] = useState('');

useEffect(() => {
	const fetchData = async () => {
		const url =
			process.env.NODE_ENV === 'development'
			? 'http://gc/article.php'
			: '/api/article.php';
		try {
			const response = await axios.get(url, {
				params: {  } //page: 'accueil-manga', nb_manga: '5'
			});
			setListe_article_site(response.data.liste_article_site);
			setListe_article_actu_site(response.data.liste_article_news);
		} catch (error) {
			console.error('Erreur lors de la récupération des données:', error);
		}
	};
	
	fetchData();

  }, []);

  return (
    <section id="home">
      <Container maxWidth="lg" sx={{ mb:4}}>

      <CustomBox variant="special" sx={{
              mt: 10,
            }}>
            <Typography variant="h5" component="h1" gutterBottom sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 700,
                  fontSize: '40px',
                  lineHeight: '40px',
                  mt: 2,
                  mb: 2,
                  color: '#0A2E52',
                }}>
              Avis de Mise à Jour
            </Typography>
            <Typography
            sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  mt: 3,
                  mb: 2,
                  color: '#0A2E52',
                }}><p>Bon bon bon ... Le plus gros chantier du site web est en cours ! Nous procédons actuellement à une réécriture complète du code du site, ce qui se fait progressivement. Cela peut entraîner la désactivation temporaire de certaines fonctionnalités. La migration est en cours tant que ce message est affiché.</p>

            <p>Si vous souhaitez signaler des problèmes, n'hésitez pas à rejoindre notre serveur Discord (lien en pied de page). Merci de votre compréhension et de votre patience !</p></Typography>
          </CustomBox>


      <CustomBox sx={{
              mt: 4
            }}>
            <Typography variant="h5" component="h1" gutterBottom sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 700,
                  fontSize: '40px',
                  lineHeight: '40px',
                  mt: 2,
                  mb: 2,
                  color: '#0A2E52',
                }}>
              Gestionnaire de collection
            </Typography>

      <Grid container spacing={4} sx={{ mt:4, mb:4 }}>
        <Grid item xs={12}>
          
				{liste_article_site && liste_article_site.length > 0 ? (
					<TuileArticle
				    href={`/article/${liste_article_actu_site[0].id}`}
				    src={`/image/article/${liste_article_actu_site[0].id}_640.webp`}
				    caption={liste_article_actu_site[0].titre}
				            />
				) : (
          <Typography>Loading...</Typography>
        )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {liste_article_site && liste_article_site.length > 0 ? (
					<TuileArticle
				    href={`/article/${liste_article_actu_site[1].id}`}
				    src={`/image/article/${liste_article_actu_site[1].id}_640.webp`}
				    caption={liste_article_actu_site[1].titre}
				            />
				) : (
          <Typography>Loading...</Typography>
        )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {liste_article_site && liste_article_site.length > 0 ? (
					<TuileArticle
				    href={`/article/${liste_article_actu_site[2].id}`}
				    src={`/image/article/${liste_article_actu_site[2].id}_640.webp`}
				    caption={liste_article_actu_site[2].titre}
				            />
				) : (
          <Typography>Loading...</Typography>
        )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {liste_article_site && liste_article_site.length > 0 ? (
					<TuileArticle
				    href={`/article/${liste_article_actu_site[3].id}`}
				    src={`/image/article/${liste_article_actu_site[3].id}_640.webp`}
				    caption={liste_article_actu_site[3].titre}
				            />
				) : (
          <Typography>Loading...</Typography>
        )}
        </Grid>
      </Grid>

<a href={`https://www.gestionnaire-collection.com/liste_article/`} target="_blank" rel="noopener noreferrer">
            <CustomChip
        icon={<NewspaperIcon />}
        color="primary"
        label="Tous les articles"
        clickable="true" 
      />
      </a>
          </CustomBox>
      <CustomBox sx={{
              mt: 4
            }}>
            <Typography variant="h5" component="h1" gutterBottom sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 700,
                  fontSize: '40px',
                  lineHeight: '40px',
                  mt: 2,
                  mb: 2,
                  color: '#0A2E52',
                }}>
              Actualité du site
            </Typography>

            {liste_article_site && liste_article_site.length > 0 ? (
          liste_article_site.map((article_site) => (
          <Box sx={{
            textAlign: 'left'
          }}>
            <Typography
            sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 600,
                  fontSize: '28px',
                  lineHeight: '28px',
                  mt: 3,
                  mb: 2,
                  color: '#0A2E52',
                }}>
                {article_site.titre}</Typography>
            <Typography
            sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '16px',
                  mt: 3,
                  mb: 2,
                  color: '#0A2E52',
                }}>{article_site.date_article}</Typography>
            <Typography
            sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  mt: 3,
                  mb: 2,
                  color: '#0A2E52',
                }}><div dangerouslySetInnerHTML={{ __html: article_site.texte}} /></Typography>
          </Box>
          ))
        ) : (
          <Typography>Loading...</Typography>
        )}

          </CustomBox>
          </Container>
    </section>
  );
};

export default Home;