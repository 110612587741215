import React, { useState } from 'react';
import { Grid, styled, Box, Button } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../context/auth-context';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link } from 'react-router-dom';

const StyledImage = styled(LazyLoadImage)(({ theme, clickable, posseder }) => ({
  width: '100%',
  transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out',
  border: posseder === 1 ? '4px solid #4CAF50' : '4px solid transparent',
  ...(clickable && {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}));


const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: 0,
  borderRadius: '50%', // Rendre le bouton circulaire
  backgroundColor: '#F3F3F3', // Fond gris clair
  width: 32, // Largeur du bouton (cercle)
  height: 32, // Hauteur du bouton (cercle)
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'opacity 0.3s ease-in-out',
}));

// Composant Volume, séparé et mémorisé
const MangaVolumeFiche = React.memo(({ volume, clickable = false, titre = "", titre_edition = ""}) => {
	const { isAuthenticated, user } = useAuth();
	const [posseder, setPosseder] = useState(volume.posseder);
	const [lu, setLu] = useState(volume.lu);
	const [interesser, setInteresser] = useState(volume.interesser);

	const handlePossederClick = () => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/manga_utilisateur.php'
					: '/api/manga_utilisateur.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ maj_posseder: posseder, id_manga_volume: volume.id }),
					credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				setPosseder(result['posseder']);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};
		fetchDonnees();
	};

	const handleLuClick = () => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/manga_utilisateur.php'
					: '/api/manga_utilisateur.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ maj_lu: posseder, id_manga_volume: volume.id }),
					credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				setLu(result['lu']);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};
		fetchDonnees();
	};

	const handleInteresserClick = () => {
		console.log("interesser");
		console.log(interesser);
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/manga_utilisateur.php'
					: '/api/manga_utilisateur.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ maj_interesser: interesser, id_manga_volume: volume.id }),
					credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				setInteresser(result['interesser']);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};
		fetchDonnees();
	};

	return (
		<Grid item xs={6} sm={4} md={2.4} key={volume.numero}>
			{clickable ? (
				<Link to={`/fiche-manga-volume/${volume.id}`} style={{ textDecoration: 'none' }}>
					<StyledImage
						alt={`${volume.titre} cover`}
						src={`https://cdn.gestionnaire-collection.com/image_manga/webp/${volume.id_edition}-${volume.numero}-m.webp`}
						effect="blur"
            clickable={true}
            posseder={posseder}
					/>
				</Link>
				) : (
				<StyledImage
					alt={`${volume.titre} cover`}
					src={`https://cdn.gestionnaire-collection.com/image_manga/webp/${volume.id_edition}-${volume.numero}-m.webp`}
					effect="blur"
					posseder={posseder}
				/>
			)}
			<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
				{isAuthenticated && user ? (
					<>
						<StyledButton onClick={handlePossederClick}>
							{posseder ? (
								<DeleteIcon color="primary" />
							) : (
								<AddIcon color="primary" />
							)}
						</StyledButton>
						<StyledButton onClick={handleLuClick}>
							{lu ? (
								<VisibilityIcon color="primary" />
							) : (
								<VisibilityOffIcon color="primary" />
							)}
						</StyledButton>
						<StyledButton onClick={handleInteresserClick}>
							{(interesser === 1 || interesser === null) && <FavoriteBorderIcon color="primary" />}
							{interesser === 2 && <FavoriteIcon sx={{ color: '#4CAF50' }} />} 
							{interesser === 0 && <HeartBrokenIcon sx={{ color: '#f44336' }} />} 
						</StyledButton>
					</>
					) : null}
      </Box>
		</Grid>
	);
});

export default MangaVolumeFiche;