import { Container,TableContainer,Paper,TableHead,TableRow,TableCell,Typography,TableBody} from '@mui/material';
import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import SousTitre from '../components/SousTitre';

import React, { useEffect, useState } from 'react';

const ProchaineSortiePerso = () => {
	const [listes_prochaine_sortie_manga_perso, setListes_prochaine_sortie_manga_perso] = useState([]);

	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ page: 'prochaine-sortie-manga-perso' }),
					credentials: 'include'
				});

				console.log("!!!!!!!!!!!!!");
				//console.log(event.target.checked)
				//console.log(response.text());

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				console.log(result);
				setListes_prochaine_sortie_manga_perso(result);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};

		fetchDonnees();
	}, []);

	const groupedByMonth = listes_prochaine_sortie_manga_perso.reduce((acc, manga) => {
		const monthYear = new Date(manga.date_publication).toLocaleString('default', {
			year: 'numeric',
			month: 'long'
		});

		if (!acc[monthYear]) {
			acc[monthYear] = [];
		}
		acc[monthYear].push(manga);
		return acc;
	}, {});

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			      {Object.keys(groupedByMonth).map((month, index) => {
        // Calculer le coût total pour chaque mois
        const totalCost = groupedByMonth[month].reduce((sum, manga) => sum + (manga.prix || 0), 0);
        
        return (
          <CustomBox key={month} sx={{ mt: index === 0 ? 10 : 4 }}>
          {index === 0 && (
          	<TitrePrincipale>Mes prochaines sorties</TitrePrincipale>
          )}
            <SousTitre variant="h5">{month}</SousTitre>




<TableContainer sx={{
mt: 10
}} component={Paper}>
	<table style={{ width: '100%', marginTop: '10px' }}>
		<TableHead>
			<TableRow>
				<TableCell style={{ textAlign: 'left' }}><Typography variant="subtitle1" fontWeight="bold">Titre</Typography></TableCell>
				<TableCell style={{ textAlign: 'center' }}><Typography variant="subtitle1" fontWeight="bold">Tome</Typography></TableCell>
				<TableCell style={{ textAlign: 'right' }}><Typography variant="subtitle1" fontWeight="bold">Date</Typography></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{groupedByMonth[month].map((manga) => (
				<TableRow key={manga.id}>
					<TableCell style={{ textAlign: 'left' }}>{manga.titre}</TableCell>
					<TableCell style={{ textAlign: 'center' }}>{manga.numero}</TableCell>
					<TableCell style={{ textAlign: 'right' }}>{manga.date_publication}</TableCell>
				</TableRow>
			))}
		</TableBody>
	</table>
</TableContainer>










				<Typography sx={{ mt: 2, fontStyle: 'italic', textAlign: 'right' }}>
					Il faudra débourser {totalCost.toFixed(2)} euros pour le mois de {month}.
				</Typography>
          </CustomBox>
        );
    })}
		</Container>
	)
}

export default ProchaineSortiePerso;