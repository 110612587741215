import React from 'react';
import { Grid, styled } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const StyledImage = styled(LazyLoadImage)(({ theme, clickable }) => ({
  width: '100%',
  transition: 'transform 0.3s ease-in-out',
  ...(clickable && {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}));

// Composant Volume, séparé et mémorisé
const MangaVolume = React.memo(({ volume, clickable = false, titre = "", titre_edition = "", showDetails = false}) => {
	return (
		<Grid item xs={6} sm={4} md={2.4} key={volume.numero}>
			{clickable ? (
				<Link to={`/fiche-manga/m/${volume.id}`}>
					<StyledImage
						alt={`${volume.titre} cover`}
						src={`https://cdn.gestionnaire-collection.com/image_manga/webp/${volume.id_edition}-${volume.numero}-m.webp`}
						effect="blur"
            clickable={true}
					/>
				</Link>
				) : (
				<StyledImage
					alt={`${volume.titre} cover`}
					src={`https://cdn.gestionnaire-collection.com/image_manga/webp/${volume.id_edition}-${volume.numero}-m.webp`}
					effect="blur"
				/>
			)}
			{showDetails && (
        <div>
					{titre} {titre_edition} Tome {volume.numero}
				</div>
      )}
		</Grid>
	);
});

export default MangaVolume;

