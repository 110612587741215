import React from 'react';
import { Typography } from '@mui/material';

const CustomTypography = ({ children, ...props }) => {
  const commonStyles = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    //color: '#0A2E52',
    textAlign: 'left',
    mt: 4,
  };

  return (
    <Typography variant="body1" component="p" gutterBottom sx={{ ...commonStyles }} {...props}>
      {children}
    </Typography>
  );
};

export default CustomTypography;