import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Palette pour le mode clair
          primary: {
            main: '#1976d2',
          },
          text: {
            primary: '#0A2E52',
          },
          background: {
            default: '#ffffff',
            customBox: '#ffffff',
          },
          nav: {
            background: '#3C42A8',  // Couleur de fond pour la navbar en mode clair
            text: '#FFFFFF',        // Couleur de texte pour la navbar en mode clair
          },
        }
      : {
          // Palette pour le mode sombre
          primary: {
            main: '#90caf9',
          },
          text: {
            primary: '#FFFFFF',
          },
          background: {
            default: '#121212',
            paper: '#1d1d1d',
            customBox: '#22262A',
          },
          nav: {
            background: '#22262A',  // Couleur de fond pour la navbar en mode clair
            text: '#FFFFFF',        // Couleur de texte pour la navbar en mode clair
          },
        }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: mode === 'light' ? '#F3F3F3' : '#000000',
        },
      },
    },
  },
  typography: {
    // Définir la typographie commune ici
  },
  // Autres personnalisations possibles
});

export const createMyTheme = (mode) => createTheme(getDesignTokens(mode));