import React from 'react';
import { Box, Typography } from '@mui/material';

const Badge = ({ src, texte }) => {
  return (
    <Box sx={{ textAlign: 'center', mb: 4 }}>
      <img src={src} alt="Badge" style={{ maxWidth: '100px', marginBottom: '8px' }} />
      <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
        {texte}
      </Typography>
    </Box>
  );
};

export default Badge;