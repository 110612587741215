import React, { useEffect, useState } from 'react';
import { Grid, styled, Box, Button,Container, Typography  } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../context/auth-context';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import CustomChip from '../components/CustomChip';


const StyledImage = styled(LazyLoadImage)(({ theme, posseder }) => ({
  width: '100%',
  height: 'auto',
  transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out',
  border: posseder === 1 ? '4px solid #4CAF50' : '4px solid transparent',
}));


const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: 0,
  borderRadius: '50%', // Rendre le bouton circulaire
  backgroundColor: '#F3F3F3', // Fond gris clair
  width: 32, // Largeur du bouton (cercle)
  height: 32, // Hauteur du bouton (cercle)
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'opacity 0.3s ease-in-out',
}));

// Composant Volume, séparé et mémorisé
const FicheMangaVolume = React.memo(() => {
	const { isAuthenticated, user } = useAuth();
	const [posseder, setPosseder] = useState(0);
	const [lu, setLu] = useState(0);
	const [interesser, setInteresser] = useState(1);
	const { id } = useParams();

	const [titre, setTitre] = useState("?");
	const [manga, setManga] = useState({ id: 'default-id' });
	const [mangaEdition, setMangaEdition] = useState(null);
	const [mangaVolume, setMangaVolume] = useState({ id_edition: 'default-id', numero: 'default-num' });

	useEffect(() => {
		const fetchData = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
				try {
					const response = await fetch(url, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ page: 'fiche-manga-volume', id : id }),
						credentials: 'include'
					});

					console.log("coucou");

					const result = await response.json();

					console.log(result);

					setTitre(result.manga[0].titre);
					setManga(result.manga[0]);
					setMangaEdition(result.manga_edition);
					setMangaVolume(result.manga_volume);

					setPosseder(result.inventaire_utilisateur.posseder);
					setLu(result.inventaire_utilisateur.lu);
					setInteresser(result.inventaire_utilisateur.interesser);


					

				} catch (error) {
					console.error('Erreur lors de la récupération des données:', error);
				}
		};

		fetchData();
	}, [id]);


















	const handlePossederClick = () => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/manga_utilisateur.php'
					: '/api/manga_utilisateur.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ maj_posseder: posseder, id_manga_volume: mangaVolume.id }),
					credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				setPosseder(result['posseder']);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};
		fetchDonnees();
	};

	const handleLuClick = () => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/manga_utilisateur.php'
					: '/api/manga_utilisateur.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ maj_lu: lu, id_manga_volume: mangaVolume.id }),
					credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				setLu(result['lu']);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};
		fetchDonnees();
	};

	const handleInteresserClick = () => {
		console.log("interesser");
		console.log(interesser);
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/manga_utilisateur.php'
					: '/api/manga_utilisateur.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ maj_interesser: interesser, id_manga_volume: mangaVolume.id }),
					credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				setInteresser(result['interesser']);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};
		fetchDonnees();
	};


	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			<CustomChip sx={{mb:-10, backgroundColor: 'primary'}} component={Link} to={`/fiche-manga/m/${manga.id}`} color="primary" label="Retour fiche manga" clickable="true" />
			<CustomBox sx={{
				mt: 10
			}}>
				<TitrePrincipale>
					{titre}
				</TitrePrincipale>




<Grid container 
  justifyContent="center" 
  alignItems="center">
	<Grid item xs={12} sm={6} md={6}>
		<StyledImage
			alt={`${manga.titre} cover`}
			src={`https://cdn.gestionnaire-collection.com/image_manga/webp/${mangaVolume.id_edition}-${mangaVolume.numero}-m.webp`}
			effect="blur"
			width='100%'
		/>
		<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
				{isAuthenticated && user ? (
					<>
						<StyledButton onClick={handlePossederClick}>
							{posseder ? (
								<DeleteIcon color="primary" />
							) : (
								<AddIcon color="primary" />
							)}
						</StyledButton>
						<StyledButton onClick={handleLuClick}>
							{lu ? (
								<VisibilityIcon color="primary" />
							) : (
								<VisibilityOffIcon color="primary" />
							)}
						</StyledButton>
						<StyledButton onClick={handleInteresserClick}>
							{(interesser === 1 || interesser === null) && <FavoriteBorderIcon color="primary" />}
							{interesser === 2 && <FavoriteIcon sx={{ color: '#4CAF50' }} />} 
							{interesser === 0 && <HeartBrokenIcon sx={{ color: '#f44336' }} />} 
						</StyledButton>
					</>
					) : null}
      </Box>
	</Grid>
</Grid>


<Grid item xs={6} sm={4} md={2.4} >
			<Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
              	Titre
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
                {titre}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Édition 
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
              	{mangaEdition ? (
									<div>{mangaEdition.titre_edition}</div>
              	) : (
									<div>Inconu</div>
              	)}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Numéro  
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
              	{mangaVolume ? (
									<div>{mangaVolume.numero}</div>
              	) : (
									<div>Inconu</div>
              	)}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Date  
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
              	{mangaVolume ? (
									<div>{mangaVolume.date_publication}</div>
              	) : (
									<div>Inconu</div>
              	)}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Prix  
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
              	{mangaVolume ? (
									<div>{mangaVolume.prix} €</div>
              	) : (
									<div>Inconu</div>
              	)}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" color="textSecondary" align="left">
                Code EAN13  
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{borderBottom: '1px solid #F3F3F3'}}>
              <Typography variant="body1" component="div" fontWeight="bold" color="#0A2E52" align="right">
              	{mangaVolume ? (
									<div>{mangaVolume.code_ean13}</div>
              	) : (
									<div>Inconu</div>
              	)}
              </Typography>
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
</Grid>




















			</CustomBox>
		</Container>
		
	);
});

export default FicheMangaVolume;