import './App.css';
import React, { useState } from 'react';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
//import PodcastCard from './components/PodcastCard';
import FicheManga from './sections/FicheManga';
import CommentRangerSesManga from './sections/Comment-ranger-ses-manga';
import Cgu from './sections/Cgu';
import AccueilManga from './sections/AccueilManga';
import Home from './sections/Home';
import './assets/fontawesome/css/all.min.css';


//Liste des sections (/pages)
import HistoriqueVersion from './sections/HistoriqueVersion';
import Connexion from './sections/Connexion';
import ProfilUtilisateur from './sections/ProfilUtilisateur';
import ProchaineSortie from './sections/ProchaineSortie';
import MangaPersonnel from './sections/MangaPersonnel';
import ProchaineSortiePerso from './sections/ProchaineSortiePerso';
import MangaPersonnelCouverture from './sections/MangaPersonnelCouverture';
import PalPerso from './sections/PalPerso';
import MangaManquantPerso from './sections/MangaManquantPerso';
import MangaEnviePerso from './sections/MangaEnviePerso';
import MangaStatistiquePerso from './sections/MangaStatistiquePerso';
import RecapMangaPersoAchat from './sections/RecapMangaPersoAchat';
import RecapMangaPersoLecture from './sections/RecapMangaPersoLecture';
import FicheMangaVolume from './sections/FicheMangaVolume';


import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createMyTheme } from './theme';



import {Box } from '@mui/material';

function App() {
  const [mode, setMode] = useState('light');

  const theme = createMyTheme(mode);

  const toggleTheme = () => {
  	console.log('mksjdgfsdofshifzseofzefez');
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<div className="App">
				<BrowserRouter >
					<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
						<Navbar />
						<Box sx={{ flex: '1' }}>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/accueil-manga/" element={<AccueilManga />} />
								<Route path="/fiche-manga/m/:id" element={<FicheManga />} />
								<Route path="/fiche-manga-volume/:id" element={<FicheMangaVolume />} />
								<Route path="/comment-ranger-ses-manga/" element={<CommentRangerSesManga />} />
								<Route path="/comment_ranger_ses_manga/" element={<CommentRangerSesManga />} />
								<Route path="/cgu/" element={<Cgu />} />
								<Route path="/historique-version/" element={<HistoriqueVersion />} />
								<Route path="/connexion/" element={<Connexion setMode={setMode} />} />
								<Route path="/profil-utilisateur/" element={<ProfilUtilisateur toggleTheme={toggleTheme} mode={mode} />} />
								<Route path="/prochaine-sortie/" element={<ProchaineSortie/>} />
								<Route path="/manga-personnel/" element={<MangaPersonnel/>} />
								<Route path="/prochaine-sortie-perso/" element={<ProchaineSortiePerso/>} />
								<Route path="/manga-personnel-couverture/" element={<MangaPersonnelCouverture/>} />
								<Route path="/manga-pal-perso/" element={<PalPerso/>} />
								<Route path="/manga-manquant-perso/" element={<MangaManquantPerso/>} />
								<Route path="/manga-envie-perso/" element={<MangaEnviePerso/>} />
								<Route path="/manga-statistique-perso/" element={<MangaStatistiquePerso/>} />
								<Route path="/recap-manga-perso-achat/" element={<RecapMangaPersoAchat/>} />
								<Route path="/recap-manga-perso-lecture/" element={<RecapMangaPersoLecture/>} />
							</Routes>
						</Box>
						<Footer />
					</Box>
				</BrowserRouter>
			</div>
		</ThemeProvider>
	);
}

export default App;
