import React from 'react';
import { Typography } from '@mui/material';


const Titre = ({ children, variant = 'h5', component = 'h1', gutterBottom = true, ...props }) => {
	return (
	<Typography
		variant={variant}
		component={component}
		gutterBottom={gutterBottom}
		sx={{
			fontFamily: 'Poppins, sans-serif',
			fontWeight: 700,
			fontSize: '40px',
			lineHeight: '40px',
			mb: 2,
			color: (theme) => theme.palette.text.primary,
			...props.sx,
		}}
		{...props}
		>
			{children}
		</Typography>
	);
}

export default Titre;