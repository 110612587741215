import { Container, Box, Grid} from '@mui/material';
import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import SousTitre from '../components/SousTitre';
import MangaVolume from '../components/MangaVolume';

import React, { useEffect, useState } from 'react';

const getMonthName = (year, month) => {
  // Crée une date avec l'année et le mois donnés
  const date = new Date(`${year}-${month}-01`);

  // Retourne le nom du mois en français
  return new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(date);
};

const formatDate = (dateString) => {
  const [year, month] = dateString.split('-');

  // Obtenez le nom du mois en français
  const monthName = getMonthName(year, month);

  return `Lecture du mois de ${monthName} ${year}`;
};

const RecapMangaPersoLecture = () => {
	const [listes_lecture_manga, setListes_lecture_manga] = useState([]);

	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ page: 'RecapMangaPersoLecture' }),
					credentials: 'include'
				});

				//console.log(event.target.checked)
				//console.log(response.text());

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				console.log(result);
				setListes_lecture_manga (result.recap_manga_perso);
				//setNb_manga_envie(result.nombre_de_manga_envie);
				//setNb_edition_envie(result.nb_edition_envie);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};

		fetchDonnees();
	}, []);

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			{Object.keys(listes_lecture_manga).map((mois,indexMois) => {
				const detailsManga = listes_lecture_manga[mois]['date_ajout_lu'];
				return (
					<CustomBox key={mois} sx={{ mt: indexMois === 0 ? 10 : 4 }}>
						{indexMois === 0 && (
							<Box sx={{mb:4}}>
								<TitrePrincipale>Mes lectures</TitrePrincipale>
							</Box>
						)}
						<Box sx={{mb:4}}>
							<SousTitre>
								{formatDate(mois)}
							</SousTitre>
						</Box>
						<Box sx={{mb:4}}>
							Nombre de tomes : {listes_lecture_manga[mois]['nombre_tomes']}
						</Box>
						{Object.keys(detailsManga).map((dateAjout,indexDateAjout) => {
							const mangas = detailsManga[dateAjout];
							return(
								<Box sx={{mb:4}}>
									<SousTitre>
										Lecture du {new Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(dateAjout))}
									</SousTitre>
									<Grid container spacing={4} justifyContent="center" sx={{mt:0, mb:4}}>
										{mangas.map((manga,indexManga) => {
											return(
												<MangaVolume key={manga.id_volume} volume={manga} clickable={true} titre={manga.titre} titre_edition={manga.titre_edition} />
											)
										})}
									</Grid>
								</Box>
							)
						})}
					</CustomBox>
				)
			})}
		</Container>
	)
};

export default RecapMangaPersoLecture;