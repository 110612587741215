// src/config/theme.js
const theme = {
  success: {
    background: '#EFFCED',
    icon: 'green',
    text: '#34992B'
  },
  error: {
    background: '#fdecea',
    icon: 'red',
    text: 'red'
  },
  info: {
    background: '#e3f2fd',
    icon: '#2196f3',
    text: '#2196f3'
  },
  warning: {
    background: '#fff3e0',
    icon: '#ff9800',
    text: '#ff9800'
  }
};

export default theme;