import { Container, Typography, TextField, Button, Box,Grid,Alert , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import CustomBox from '../components/CustomBox';
import CustomChip from '../components/CustomChip';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormControlLabel from '@mui/material/FormControlLabel'; 
import Checkbox from '@mui/material/Checkbox';

const Connexion = ({ setMode }) => {
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const [errorCreationCompte, setErrorCreationCompte] = useState('');
    const { setIsAuthenticated, setUser } = useAuth();
	const [open, setOpen] = useState(false);
	const [openModalNouveauCompte, setOpenModalNouveauCompte] = useState(false);
	const [newComposant, setNewComposant] = useState({ pseudo_nouveau_mdp: '', mail_nouveau_mdp: '' });
	const [isChecked, setIsChecked] = useState(false);
	const [errors, setErrors] = useState({});

	const [formData, setFormData] = useState({
		username: '',
		password: ''
	});

	const [formDataCreationCompte, setFormDataCreationCompte] = useState({
		nouveau_pseudo: '',
		nouveau_mdp: '',
		nouveau_mdp_confirmation: '',
		nouveau_mail: '',
		nouveau_mail_confirmation: ''
	});

	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};

	const handleChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClickModalNouveauCompte = () => {
		setOpenModalNouveauCompte(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseModalNouveauCompte = () => {
		setOpenModalNouveauCompte(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewComposant({ ...newComposant, [name]: value });
	};

	const handleInputChangeCreationCompte = (e) => {
		const { name, value } = e.target;
		setFormDataCreationCompte({ ...formDataCreationCompte, [name]: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		//setFormData({ ...formData, password: '' });

		const url =
			process.env.NODE_ENV === 'development'
				? 'http://gc/connexion_utilisateur.php'
				: '/api/connexion_utilisateur.php';

		try {
			// Remplacer `url_de_votre_api` par l'URL de votre API de connexion
			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(formData),
        		credentials: 'include'
			});

			if (!response.ok) throw new Error('Réponse réseau non réussie');

			const result = await response.json();

			if (result.status === 'error') {
        	    setError(result.message);
        	}
        	else{
        		setIsAuthenticated(true);
                setUser({
                    id: result.utilisateur.id,
                    pseudo: result.utilisateur.pseudo,
                    rank: result.utilisateur.rank
                });
                if(result.utilisateur.mode_sombre){
                	setMode('dark');
                }
        		navigate('/');
        	}


		} catch (error) {
			setError('Erreur 1  lors de la connexion au serveur');
		}
	};

	const validateForm = () => {
		const newErrors = {};
		if (!formDataCreationCompte.nouveau_pseudo) {
			newErrors.nouveau_pseudo = 'Le pseudo est obligatoire';
		}
		if (!formDataCreationCompte.nouveau_mdp) {
			newErrors.nouveau_mdp = 'Le mot de passe est obligatoire';
		}
		if (!formDataCreationCompte.nouveau_mdp_confirmation) {
			newErrors.nouveau_mdp_confirmation = 'La confirmation du mot de passe est obligatoire';
		}
		else if (formDataCreationCompte.nouveau_mdp !== formDataCreationCompte.nouveau_mdp_confirmation) {
			newErrors.nouveau_mdp_confirmation = 'Les mots de passe ne correspondent pas';
		}
		if(!formDataCreationCompte.nouveau_mail) {
			newErrors.nouveau_mail = 'L\'adresse mail est obligatoire';
		}
		if (!formDataCreationCompte.nouveau_mail_confirmation) {
			newErrors.nouveau_mail_confirmation = 'La confirmation de l\'adresse mail est obligatoire';
		}
		else if (formDataCreationCompte.nouveau_mail !== formDataCreationCompte.nouveau_mail_confirmation) {
			newErrors.nouveau_mail_confirmation = 'Les adresses mail ne correspondent pas';
		}
		if (!isChecked) {
			newErrors.terms = 'Vous devez accepter les termes et conditions';
		}

		setErrors(newErrors);

		return Object.keys(newErrors).length === 0;
	};

	const handleSubmitCreationCompte = async (event) => {
		event.preventDefault();
		if(validateForm()){

			const url =
			process.env.NODE_ENV === 'development'
				? 'http://gc/connexion_utilisateur.php'
				: '/api/connexion_utilisateur.php';

			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(formDataCreationCompte),
        			credentials: 'include'
				});

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();

				if (result.status === 'error') {
					//console.log(result.message);
					setErrorCreationCompte(result.message);
				}
				else{
	        		setIsAuthenticated(true);
	        		setUser({
						id: result.compte_nouvellement_creer.id,
						pseudo: result.compte_nouvellement_creer.pseudo,
						rank: result.compte_nouvellement_creer.rank
					});
					navigate('/');
					/*setIsAuthenticated(true);
					setUser({
						id: result.utilisateur.id,
						pseudo: result.utilisateur.pseudo,
						rank: result.utilisateur.rank
					});*/
					//
				}
			} catch (error) {
				setError('Erreur lors de la connexion au serveur');
			}
		}
	};

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			<CustomBox sx={{mt: 10}} >
				<Typography variant="h5" component="h1" gutterBottom sx={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 700,
					fontSize: '40px',
					lineHeight: '40px',
					color: '#0A2E52',
					mb:4
				}}>
					Connexion
				</Typography>

				<Grid container justifyContent="center" alignItems="center" >
					<Grid item xs={12} sm={8} md={4}>
						<Box
							component="form"
							onSubmit={handleSubmit}
							md={4}
						>
							<TextField
								fullWidth
								label="Username"
								variant="standard"
								name="username"
								value={formData.username}
								onChange={handleChange}
								required
								sx={{ mb:4 }}
							/>
							<TextField
								fullWidth
								label="Password"
								variant="standard"
								name="password"
								type="password"
								value={formData.password}
								onChange={handleChange}
								required
								sx={{ mb:4 }}
							/>
							{error && <Alert severity="error" sx={{ m: 1 }}>{error}</Alert>}
							<Button type="submit" style={{ border: 'none', background: 'none', padding: 0 }}>
								
								<CustomChip icon={<AddCircleOutlineIcon />} color="primary" label="Connexion" clickable="true" />
							</Button>
						</Box>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item xs={12} sm={8} md={4} mt={1}>
						<Button  variant="standard" onClick={handleClickOpen} clickable="true">
							Mot de passe oublié
						</Button>
					</Grid>
				</Grid>
				<Grid container justifyContent="right" alignItems="right">
					<Grid item xs={12} sm={8} md={4} mt={2}>
						<CustomChip icon={<AddCircleOutlineIcon />} label="Créer un compte" onClick={handleClickModalNouveauCompte} clickable="true" />
					</Grid>
				</Grid>
			</CustomBox>









			{/* Modal changement de mot de passe */}
	<Dialog open={open} onClose={handleClose}>
		<DialogTitle>Mot de passe perdu </DialogTitle>
		<DialogContent>

			<DialogContentText>
				Mot de passe perdu? Merci de renseigner votre adresse mail associé au compte. <br />
				PS : ne fonctionne pas actuellement, une correction de l outil est prévu.
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				name="pseudo_nouveau_mdp"
				label="Pseudo"
				type="text"
				fullWidth
				variant="standard"
				value={newComposant.pseudo_nouveau_mdp}
				onChange={handleInputChange}
			/>
			<TextField
				autoFocus
				margin="dense"
				name="pseudo_nouveau_mdp"
				label="Pseudo"
				type="text"
				fullWidth
				variant="standard"
				value={newComposant.pseudo_nouveau_mdp}
				onChange={handleInputChange}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="secondary">
				Annuler
			</Button>
			<Button onClick={handleSubmit} color="primary">
				 Ajouter
			</Button>
		</DialogActions>
	</Dialog>

	{/* Modal Creation de compte */}
	<Dialog open={openModalNouveauCompte} onClose={handleClose}>
		<DialogTitle>Création de compte</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Bienvenue sur ce logiciel web. Rappel important : il est actuelement en développement, beaucoup de fonctionnalité sont amener a venir, et les bugs, à partir. De plus, vous êtes très fortement invité à faire un retour d'expérience afin de bonnifier cet outils.
			</DialogContentText>
			<DialogContentText sx={{ textAlign:'right'}}>
				Marc-Antoine
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				name="nouveau_pseudo"
				label="Pseudo"
				type="text"
				fullWidth
				variant="standard"
				value={formDataCreationCompte.nouveau_pseudo}
				onChange={handleInputChangeCreationCompte}
				required
				error={!!errors.nouveau_pseudo}
				helperText={errors.nouveau_pseudo}
			/>
			<TextField
				autoFocus
				margin="dense"
				name="nouveau_mdp"
				label="Mot de passe"
				type="password"
				fullWidth
				variant="standard"
				value={formDataCreationCompte.nouveau_mdp}
				onChange={handleInputChangeCreationCompte}
				required
				error={!!errors.nouveau_mdp}
				helperText={errors.nouveau_mdp}
			/>
			<TextField
				autoFocus
				margin="dense"
				name="nouveau_mdp_confirmation"
				label="Mot de passe (confirmation)"
				type="password"
				fullWidth
				variant="standard"
				value={formDataCreationCompte.nouveau_mdp_confirmation}
				onChange={handleInputChangeCreationCompte}
				required
				error={!!errors.nouveau_mdp_confirmation}
				helperText={errors.nouveau_mdp_confirmation}
			/>
			<TextField
				autoFocus
				margin="dense"
				name="nouveau_mail"
				label="Mail"
				type="text"
				fullWidth
				variant="standard"
				value={formDataCreationCompte.nouveau_mail}
				onChange={handleInputChangeCreationCompte}
				required
				error={!!errors.nouveau_mail}
				helperText={errors.nouveau_mail}
			/>
			<TextField
				autoFocus
				margin="dense"
				name="nouveau_mail_confirmation"
				label="Mail (confirmation)"
				type="text"
				fullWidth
				variant="standard"
				value={formDataCreationCompte.nouveau_mail_confirmation}
				onChange={handleInputChangeCreationCompte}
				required
				error={!!errors.nouveau_mail_confirmation}
				helperText={errors.nouveau_mail_confirmation}
			/>
			<a class="" target="_blank" rel="noopener noreferrer" href="/cgu/">C.G.U.</a>
			<FormControlLabel
				control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
				label="J'accepte les termes et conditions"
			/>
			{errorCreationCompte && <Alert severity="error">{errorCreationCompte}</Alert>}
		</DialogContent>
		<DialogActions>
			<Button onClick={handleCloseModalNouveauCompte} color="secondary">
				Annuler
			</Button>
			<Button onClick={handleSubmitCreationCompte} color="primary">
				 Créer un compte
			</Button>
		</DialogActions>
	</Dialog>



















		</Container>
	);

};

export default Connexion;