import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const PodcastCard = ({ date, title, transcriptionLink, audioSrc }) => {
  return (
    <Box sx={{
      borderRadius: '10px',
      padding: '16px',
      textAlign: 'center',
      maxWidth: '300px',
      margin: 'auto',
    }}>
      <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '12px',
                  color: '#0A2E52', }}>{date}</Typography>

      <Typography variant="h6" sx={{ 
      fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '25px',
                  color: '#0A2E52',
       }}>{title} | <Link href={transcriptionLink} sx={{ fontSize: '12px', color: '#1A73E8' }}>Transcription</Link></Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
        <audio
                    controls
                    src={audioSrc}>
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
      </Box>
    </Box>
  );
};

export default PodcastCard;