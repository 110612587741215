import { Container,Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import VersionList from '../components/VersionList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAuth } from '../context/auth-context';
import CustomChip from '../components/CustomChip';

const HistoriqueVersion = () => {
	const [listes_versions, setListes_versions] = useState('');
	const [loading, setLoading] = useState(true);	
  const { isAuthenticated, user } = useAuth();
  const [open, setOpen] = useState(false);
	const [newComposant, setNewComposant] = useState({ numero_version: '', date_version: '' });

	useEffect(() => {
	    const fetchData = async () => {
	    	const url =
		        process.env.NODE_ENV === 'development'
			    		? 'http://gc/info-page.php'
							: '/api/info-page.php';
	    	try {
	    		const response = await axios.get(url, {
						params: { page: 'historique-version'}
					});

console.log("réponse du serveur pour l'info page");
console.log(response.data.liste_version);
console.log("FINNNNNNNNNNNNNNNNNNNNNNNNNN réponse du serveur pour l'info page");
					setListes_versions(response.data.liste_version);
					setLoading(false);
	    	} catch (error) {
        		console.error('Erreur lors de la récupération des données:', error);
    		}
    	};

      fetchData();

	}, []);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("!");
    console.log(newComposant);
    setNewComposant({ ...newComposant, [name]: value });
    console.log("!!!");
    console.log(newComposant);
  };

  const handleSubmit = async () => {
		const updatedComposant = { ...newComposant };

      const fetchData = async () => {
        const url =
					process.env.NODE_ENV === 'development'
						? 'http://gc/historique_version.php'
						: '/api/historique_version.php';
        try {
					const response = await fetch(url, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({
							action: 'ajouterVersion',
							elementAAjouter : updatedComposant
						}),
						credentials: 'include'
					});

					console.log("Alors ?");
					if (!response.ok) throw new Error('Réponse réseau non réussie');

					const result = await response.json();

					const updatedComposantAInserrer = {id: parseInt(result['id_inserrer']), ...updatedComposant, liste_composant: [] };

					setListes_versions([updatedComposantAInserrer, ...listes_versions ]);

					console.log("FINNNNNNN Alors ?");
    console.log("Liste version2");
		console.log(result);
    console.log(listes_versions);

					console.log("Alors ?");
					console.log("Alors ?");


          //setListes_versions(response.data.liste_version);
          //setLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        }
      };

      fetchData();










/*
  	console.log('plop');
    console.log(updatedComposant);
		setListes_versions([updatedComposant, ...listes_versions ]);
    console.log("Liste version");
    console.log(listes_versions);*/
		handleClose();
  };

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			{isAuthenticated && user.rank === 10 && (
				<CustomChip color="primary" sx={{ mt:4, backgroundColor: '#6c757d' }} icon={<AddCircleOutlineIcon />} label="Nouvelle modification" onClick={handleClickOpen} clickable={true} />
			)}
			<VersionList versions={listes_versions} />

			<Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter une nouvelle version</DialogTitle>
        <DialogContent>
          <DialogContentText>
            DDDRemplissez les champs suivants pour ajouter une nouvelle version.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="numero_version"
            label="Numero de version"
            type="text"
            fullWidth
            value={newComposant.numero_version}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="date_version"
            label="Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={newComposant.date_version}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
		</Container>


)}

export default HistoriqueVersion;