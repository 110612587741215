import React, { useState } from 'react';
import {List, ListItem, ListItemText, Divider,ListItemIcon, IconButton  , Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField   } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BugReportIcon from '@mui/icons-material/BugReport';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BuildIcon from '@mui/icons-material/Build'; // Icône pour "Modification"
import CategoryIcon from '@mui/icons-material/Category'; // Icône pour "Divers"
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../context/auth-context';
import axios from 'axios';
import CustomChip from '../components/CustomChip';
import SousTitre from '../components/SousTitre';

const VersionItem = ({ version, isFirst, onDelete }) => {
  const { isAuthenticated, user } = useAuth();
  const [composants, setComposants] = useState(version.liste_composant);
  const [open, setOpen] = useState(false);
  const [newComposant, setNewComposant] = useState({ type: '', contenue: '' });

  const handleDelete = async (id) => {
		const url =
			process.env.NODE_ENV === 'development'
				? 'http://gc/delete_composant.php'
				: '/api/delete_composant.php';

		try {
      const response = await axios.delete(url, { data: { id }, // Envoyer l'ID en JSON
        headers: {
          'Content-Type': 'application/json', // Définir le type de contenu
        },
      });

      if (response.data.success) {
				setComposants((prevComposants) =>
					prevComposants.filter((composant) => composant.id !== id)
				);
      } else {
        console.error('Failed to delete component:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting component:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("!");
    console.log(newComposant);
    setNewComposant({ ...newComposant, [name]: value });

    console.log(newComposant);
  };



  const handleSubmit = async () => {
  	const url =
      process.env.NODE_ENV === 'development'
        ? 'http://gc/add_composant.php'
        : '/api/add_composant.php';


    try {
      const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					version_id: version.id,
					type: newComposant.type,
					contenue: newComposant.contenue,
				}),
				credentials: 'include'
			});


      if (!response.ok) throw new Error('Réponse réseau non réussie');

      const result = await response.json();

      console.log(result);




      if (result['success']) {
				const newId = result['id'];
				const updatedComposant = { ...newComposant, id: newId };
				setComposants([updatedComposant, ...composants ]);
				handleClose();
			} else {
				console.error('Failed to add component:', response.data.error);
			}





		} catch (error) {
			console.error('Erreur lors de la récupération des données:', error);
		}






  };

  return (
  <div>
		<SousTitre style={{ textAlign: 'center' }}>
			Version {version.numero_version} - {new Date(version.date_version).toLocaleDateString()}
		</SousTitre>
           <List>
        {composants && composants.length > 0 ? (
          composants.map((composant, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                {composant.type === 'Ajout' && (
                  <ListItemIcon>
                    <AddCircleIcon style={{ fontSize: 30 , color: '#3f51b5'}}/>
                  </ListItemIcon>
                )}
                {composant.type === 'Correction de bug' && (
                  <ListItemIcon>
                    <BugReportIcon style={{ fontSize: 30, color: '#f44336' }} />
                  </ListItemIcon>
                )}
                {composant.type === 'Modification' && (
                  <ListItemIcon>
                    <BuildIcon style={{ fontSize: 30, color: '#ff9800' }} /> {/* Icône pour "Modification" */}
                  </ListItemIcon>
                )}
                {composant.type === 'Divers' && (
                  <ListItemIcon>
                    <CategoryIcon style={{ fontSize: 30, color: '#9c27b0' }} /> {/* Icône pour "Divers" */}
                  </ListItemIcon>
                )}
                {composant.type !== 'Ajout' && composant.type !== 'Correction de bug' && composant.type !== 'Modification' && composant.type !== 'Divers' && (
                  <ListItemIcon>
                    <HelpOutlineIcon style={{ fontSize: 30, color: '#9e9e9e' }} /> {/* Icône par défaut */}
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={composant.type + ' : ' + composant.contenue}
                  primaryTypographyProps={{ variant: 'body2', color: 'textPrimary' }}
                />
                {isAuthenticated && user.rank === 10 && (
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(composant.id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
              </ListItem>
              {index < composants.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))
        ) : (
          <ListItem>
            <ListItemText
              primary="Aucun composant disponible pour cette version."
              primaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
            />
          </ListItem>
        )}
      </List>


    {isAuthenticated && user.rank === 10 && (
    		<CustomChip color="primary" sx={{ backgroundColor: '#6c757d' }} label="Nouvelle modification " onClick={handleClickOpen} clickable={true} />
      )}


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter un nouveau composant</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Remplissez les champs suivants pour ajouter un nouveau composant à cette version.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="type"
            label="Type"
            type="text"
            fullWidth
            value={newComposant.type}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="contenue"
            label="Contenu"
            type="text"
            fullWidth
            value={newComposant.contenue}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  );
};

export default VersionItem;
