import { Container,TableContainer,Paper,Table,TableHead,TableRow,TableCell,Typography,TableBody} from '@mui/material';
import CustomBox from '../components/CustomBox';
import TitrePrincipale from '../components/TitrePrincipale';
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';

const MangaPersonnel = () => {
	const [listes_manga_perso, setListes_manga_perso] = useState([]);

	useEffect(() => {
		const fetchDonnees = async () => {
			const url =
				process.env.NODE_ENV === 'development'
					? 'http://gc/info-page.php'
					: '/api/info-page.php';
			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ page: 'manga-personnel' }),
					credentials: 'include'
				});

				console.log("!!!!!!!!!!!!!");
				//console.log(event.target.checked)
				//console.log(response.text());

				if (!response.ok) throw new Error('Réponse réseau non réussie');

				const result = await response.json();
				console.log(result);
				setListes_manga_perso(result);

			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		};

		fetchDonnees();
	}, []);

	return (
		<Container maxWidth="lg" sx={{ mb:4}}>
			<CustomBox sx={{
				mt: 10
			}}>
				<TitrePrincipale>Mes mangas</TitrePrincipale>
				<TableContainer sx={{
					mt: 10
				}} component={Paper}>
					<Table aria-label="messages table">
						<TableHead>
							<TableRow>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Titre</Typography></TableCell>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Editeur</Typography></TableCell>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Type</Typography></TableCell>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Nombre</Typography></TableCell>
								<TableCell><Typography variant="subtitle1" fontWeight="bold">Etat</Typography></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>



							{listes_manga_perso.map((manga_perso) => (
								<TableRow key={manga_perso.id}>
									<TableCell>
										<Link to={`/fiche-manga/m/${manga_perso.id}`} style={{ color: 'inherit' }}>
											{manga_perso.titre}
										</Link>
									</TableCell>
									<TableCell>{manga_perso.editeur}</TableCell>
									<TableCell>{manga_perso.genre}</TableCell>
									<TableCell>{manga_perso.nb_manga_posseder}</TableCell>
									<TableCell>{manga_perso.etat}</TableCell>
								</TableRow>
							))}


						</TableBody>
					</Table>
				</TableContainer>
			</CustomBox>
		</Container>
	)
}

export default MangaPersonnel;